import React, {useEffect, useRef, useState} from "react";
import PageHeader from "../components/Elements/pageHeader";
import "./kotStyle.css";
import {
    fetchAllKot,
    getPendingKotFxn,
    updateKotStatusFxn,
    updateStatusKotFxn,
} from "./kot/actions";
import _ from "lodash";
import {useSelector, useDispatch, connect} from "react-redux";
import {
    Popconfirm,
} from "../components/Elements/appUtils";
import moment from "moment";
import {getTableName, getKotProStatus} from "../components/_utils/_utils";
import KotPrintDesign from "./print/kotPrint";
import {useReactToPrint} from "react-to-print";
import kotList from "./kot/kotList";

const KotView = (props) => {
    let {currentUser} = props

    const componentRef = useRef();
    let dispatch = useDispatch();
    let [kotList, setKotList] = useState([]);
    let [kotRecord, setKotRecord] = useState({});
    let [refreshKey, setRefreshKey] = useState(moment());
    const [kotTimers, setKotTimers] = useState({});
    const [timer, setTimer] = useState(0);
    const [timers, setTimers] = useState({});
    const [pausedTime, setPausedTime] = useState(0);
    const [stoppedTimers, setStoppedTimers] = useState({});

    const formatTimer = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
            remainingSeconds
        ).padStart(2, "0")}`;
        return formattedTime;
    };

    const startItemTimer = (item, itemId) => {
        if (!areAllItemsDone(item)) {
            const intervalId = setInterval(() => {
                setKotTimers((prevTimers) => ({
                    ...prevTimers,
                    [itemId]: prevTimers[itemId] ? prevTimers[itemId] + 1 : 1,
                }));
            }, 1000);

            setKotTimers((prevTimers) => ({...prevTimers, [itemId]: intervalId}));
            setPausedTime(0);
        } else {
            setPausedTime(moment());
        }
    };

    const stopItemTimer = (kotId) => {
        const areAllItemsDone = kotList.find(
            (item) => item._id === kotId && areAllItemsDone(item)
        );

        if (areAllItemsDone) {
            clearInterval(kotTimers[kotId]);
            setStoppedTimers((prevStoppedTimers) => ({
                ...prevStoppedTimers,
                [kotId]: formatTimer(kotTimers[kotId]),
            }));
        } else {
            window.location.reload();
        }
    };

    const stopTimer = (kotId) => {
        clearInterval(timers[kotId]);
        setTimer(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data} = await fetchAllKot({
                    status: "Pending",
                    results: 1000,
                    count: 1000,
                });

                const pendingKots = data.filter((item) => item.status === "Pending");
                setKotList(pendingKots);
                data.forEach((item) => {
                    if (!areAllItemsDone(item)) {
                        startItemTimer(item, item._id);
                    }
                });
            } catch (error) {
                console.error("Error fetching Kot data:", error);
            }
        };

        fetchData();

        return () => {
            Object.values(timers).forEach((intervalId) => clearInterval(intervalId));
        };
    }, []);

    const updateKot = async (data, productId = "", timer) => {
        try {
            const timerValue = kotTimers[data._id] || 0;

            const formattedTimer = formatTimer(timerValue);

            const {success} = await updateKotStatusFxn({
                productId,
                kotId: data._id,
                timer: timer,
            });

            if (success) {
                if (productId) {
                    const updatedKotList = kotList.map((kotItem) => {
                        if (kotItem._id === data._id) {
                            const updatedProducts = kotItem.products.map((pro) => {
                                if (pro._id === productId) {
                                    pro.status = "Done";
                                }
                                return pro;
                            });

                            return {
                                ...kotItem,
                                products: updatedProducts,
                                timer: timerValue,
                            };
                        }
                        return kotItem;
                    });

                    setKotList(updatedKotList);
                }
            }
        } catch (error) {
            console.error("Error updating Kot status:", error);
        }
    };

    useEffect(() => {
        events.loadKots();

        kotList.forEach((item) => {
            startItemTimer(item, item._id);
        });

        return () => {
            Object.values(kotTimers).forEach((intervalId) =>
                clearInterval(intervalId)
            );
            // setKotTimers({});
        };
    }, []);

    const playSoundFxn = () => {
        setTimeout(() => {
            document.getElementById("mySound").play();
        }, 500);
    };
    const play2SoundFxn = () => {
        setTimeout(() => {
            document.getElementById("mySound2").play();
        }, 500);
    };
    const events = {
        loadKots: async () => {
            let {data} = await fetchAllKot();
            // setKotList(data);
        },
        updateKot: async (data, productId = "") => {
            let {success, data: kotData} = await updateKotStatusFxn({
                productId,
                kotId: data._id,
            });
            if (success && productId) {
                let cloneData = _.clone(kotList);
                let findKot = _.find(cloneData, (item) => {
                    return item._id == data._id;
                });
                if (findKot) {
                    let findPro = _.find(findKot.products, (item) => {
                        return item._id == productId;
                    });
                    if (findPro) {
                        findPro.status = "Done";
                    }
                    if (kotData.status == "Pending") {
                        setKotList(cloneData);
                    }
                }
            }
        },
        kotDoneFxn: (kotIds) => {
            let cloneData = _.clone(kotList);
            cloneData = _.reject(cloneData, (item) => {
                return kotIds.includes(item._id);
            });
            setKotList(cloneData);
        },
        refreshKot: (data) => {
            let kitchenUser = ['kitchen', 'tandoor'];
            let resetUsers = ['admin', 'cashier', 'caption', 'account']
            if (currentUser.userType == "beverages" && data.categoryType == "beverages") {
                setFxn()
            } else if (currentUser.userType == "tandoor" && data.categoryType == "tandoor") {
                setFxn()
            } else if (currentUser.userType == "kitchen" && data.categoryType == "kitchen") {
                setFxn()
            } else if (resetUsers.includes(data.categoryType)) {
                setFxn()
            }

            function setFxn() {
                setKotList((prevKotList) => {
                    let cloneData = [...prevKotList];
                    if (data && data.status === "Done") {
                        cloneData = _.reject(cloneData, (item) => {
                            return item._id == data._id;
                        });

                        /* const updatedKotList = cloneData.map((item) => {
                                       if (item._id === data._id) {
                                           const updatedProducts = item.products.map((pro) => {
                                               if (pro._id === data.productId) {
                                                   return {...pro, status: "Done"};
                                               }
                                               return pro;
                                           });
                                           return {...item, products: updatedProducts};
                                       }
                                       return item;
                                   });*/
                        console.log(cloneData, "-------------updatedKotList----------");
                        return cloneData;
                    } else {
                        const findKot = cloneData.find((item) => item._id === data._id);

                        if (findKot) {
                            findKot.products = data.products;
                            if (data.playBuzz) {
                                play2SoundFxn();
                            }
                        } else {
                            cloneData.unshift(data);
                            playSoundFxn();

                            // Start the timer for the newly added item
                            startItemTimer(data, data._id);
                        }

                        if (data.kotSubmitType === "kotAndPrint") {
                            setKotRecord(data);
                        }

                        setRefreshKey(moment());

                        return cloneData;
                    }
                });
            }
        },
    };

    const resp = useSelector((state) => {
        let {isRefreshKot, kotData, isKotSaved, kotIds} = state.global;
        if (isRefreshKot) {
            events.refreshKot(kotData);
            dispatch({type: "REFRESH_KOT", kotData: {}, isRefreshKot: false});
        }
        if (isKotSaved) {
            events.kotDoneFxn(kotIds);
            dispatch({type: "KOT_SAVED", kotIds: [], isKotSaved: false});
        }
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        // printerName: "TVS-E RP 3230 Beverages"
        onBeforeGetContent: () => ({
            printerName: 'TVS-E RP 3230 Beverages',
        }),
        onAfterPrint: () => {
            // setKotRecord({})
        }
    });


    useEffect(() => {
        if (kotRecord && kotRecord._id) {
            setTimeout(() => {
                handlePrint();
            }, 200)
        }
    }, [kotRecord]);
    const areAllItemsDone = (item) => {
        const allItemsDone = item.products.every((pro) => pro.status === "Done");
        if (allItemsDone) {
            return stoppedTimers[item._id] || formatTimer(kotTimers[item._id]);
        }
        return false;
    };

    const handleReadyClick = async (e, itemId) => {
        e.preventDefault();
        let resp = await updateStatusKotFxn({_id: itemId});
        window.location.reload();
        console.log(resp);
    };

    return (
        <>
            <PageHeader>
                <div className="instruction d-flex justify-content-between">
                    <div className="left w-25">
                        <div className={"form-group d-flex"}>
                            <input
                                type="text"
                                className={"form-control"}
                                placeholder="Enter Kot/Order No."
                            />
                            <button className="btn btn-outline-success btn-sm">Search</button>
                        </div>
                    </div>
                    <div
                        className="right"
                        style={{display: "flex", flexDirection: "row-reverse"}}
                    >
                        <p style={{margin: "0 10px"}} className="with-dot-PickUp">
                            PickUp
                        </p>
                        <p style={{margin: "0 10px"}} className="with-dot-Dine">
                            Dine in
                        </p>
                        <p style={{margin: "0 10px"}} className="with-dot-Other">
                            Other
                        </p>
                        <p style={{margin: "0 10px"}} className="with-dot-Website">
                            Website
                        </p>
                        <p style={{margin: "0 10px"}} className="with-dot-Limit-Exceed">
                            Limit Exceed
                        </p>
                        <p style={{margin: "0 10px"}} className="with-dot-Delivery">
                            Delivery
                        </p>
                    </div>
                </div>
                <div></div>

                <div className={"row"} key={refreshKey}>
                    {kotList && kotList.length
                        ? kotList.map((item) => {
                            return (
                                <>
                                    <div className={"col-md-3"} key={item._id}>
                                        <div className="card">
                                            <div className="header-row">
                                                <div className="header-cell">
                                                    <h6
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {getTableName(item.tableNo)}
                                                    </h6>
                                                    <p style={{fontSize: "11px", color: "white"}}>
                                                        DINE IN
                                                    </p>
                                                </div>
                                                <div className="header-cell">
                                                    <h6 style={{fontSize: "12px", color: "white"}}>
                                                        {item.kotNo}
                                                    </h6>
                                                    <p style={{fontSize: "11px", color: "white"}}>
                                                        KOT No.
                                                    </p>
                                                </div>
                                                <div className="header-cell">
                                                    <h6
                                                        style={{
                                                            fontSize: "12px",
                                                            color: "white",
                                                        }}
                                                    >
                                                        {moment(item.time).format("hh:mm")}
                                                    </h6>
                                                    <p style={{fontSize: "11px", color: "white"}}>
                                                        MM:SS
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="section" style={{marginTop: "-3%"}}>
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Item</th>
                                                        <th scope="col" style={{textAlign: "right"}}>
                                                            Qty
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {item.products && item.products.length
                                                        ? item.products.map((pro) => {
                                                            let isAllItemsDone = areAllItemsDone(item);

                                                            let rowData = (
                                                                <tr
                                                                    key={pro._id + pro.status}
                                                                    onDoubleClick={() => {
                                                                        updateKot(item, pro._id, item.timer);
                                                                    }}
                                                                    style={{
                                                                        color:
                                                                            pro.status === "Done"
                                                                                ? "gray"
                                                                                : pro.priority === "High" &&
                                                                                pro.status === "Pending"
                                                                                    ? "white"
                                                                                    : "inherit",
                                                                        fontStyle:
                                                                            pro.status === "Done"
                                                                                ? "italic"
                                                                                : "normal",
                                                                        backgroundColor:
                                                                            pro.status === "Done"
                                                                                ? "lightgray"
                                                                                : pro.priority === "High" &&
                                                                                pro.status === "Pending"
                                                                                    ? "lightcoral"
                                                                                    : "inherit",
                                                                        textShadow:
                                                                            pro.status === "Done"
                                                                                ? "1px 1px 2px rgba(0, 0, 0, 0.2)"
                                                                                : "none",
                                                                        fontSize:
                                                                            pro.priority === "High" &&
                                                                            pro.status === "Pending"
                                                                                ? "16px"
                                                                                : "inherit",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <th>
                                                                        <div
                                                                            className={`d-flex ${
                                                                                pro.priority === "High" &&
                                                                                pro.status == "Pending"
                                                                                    ? "blinking-text"
                                                                                    : ""
                                                                            }`}
                                                                        >
                                                                            {getKotProStatus(pro.status)}
                                                                            {pro.productId && pro.productId.name
                                                                                ? pro.productId.name
                                                                                : ""}{" "}
                                                                            {pro.productId && pro.variant
                                                                                ? ` - ${pro.variant}`
                                                                                : ""}
                                                                            {pro.productId && pro.specialNote
                                                                                ? ` (${pro.specialNote})`
                                                                                : ""}
                                                                        </div>

                                                                        <br/>
                                                                    </th>
                                                                    <th
                                                                        style={{
                                                                            textAlign: "right",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        {pro.quantity}
                                                                    </th>
                                                                </tr>
                                                            );

                                                            return rowData;
                                                        })
                                                        : null}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="card-footer d-inline-flex justify-content-between">
                                                <div>
                                                    <Popconfirm
                                                        title={"Are You Sure Print Slip?"}
                                                        okText="Yes"
                                                        cancelText="No"
                                                        onConfirm={() => {
                                                            setKotRecord(item);
                                                            setTimeout(() => {
                                                                setKotRecord();
                                                            }, 200);
                                                        }}
                                                    >
                                                        <div
                                                            className="btn btn-outline-primary btn-sm"

                                                            //   onClick={() => {
                                                            //     setKotRecord(item);
                                                            //   }}
                                                        >
                                                            <i
                                                                className="fa fa-print"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    </Popconfirm>
                                                </div>
                                                <span style={{margin: "10px"}}>
                            <div style={{color: "green", fontSize: "12px"}}>
                              {/* {areAllItemsDone(item) ? `${formatTimer(item.timer)}` : `Timer: ${formatTimer(kotTimers[item._id])}`} */}
                            </div>
                          </span>
                                                <button
                                                    className={`btn btn-danger btn-sm ${
                                                        areAllItemsDone(item) ? "bg-green" : ""
                                                    }`}
                                                    // onClick={(e) => {
                                                    //     e.preventDefault();
                                                    //     if (areAllItemsDone(item)) {
                                                    //         stopItemTimer(item._id);

                                                    //     } else {
                                                    //         startItemTimer(item._id);
                                                    //     }
                                                    // }}
                                                    // onClick={handleReadyClick(item._id)}
                                                    onClick={(e) => {
                                                        handleReadyClick(e, item._id);
                                                    }}
                                                >
                                                    Food is Ready
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })
                        : null}
                </div>

                <div style={{display: "none"}}>
                    {kotRecord ? (
                        <KotPrintDesign ref={componentRef} kotRecord={kotRecord}/>
                    ) : null}
                </div>
            </PageHeader>
        </>
    );
};

const mapStateToProps = ({counter, global}) => ({
    currentUser: global.currentUser
})
const KotViewComponent = connect(
    mapStateToProps,
    null
)(KotView)
export default KotViewComponent;
