import React, { Suspense } from "react";


import PrintSale from "../../containers/sale/printSale";

export default function SaleLayout(props) {
  <>
   
      <PrintSale />
   
  </>;
}
