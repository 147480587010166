import {
    addUserApi,
    userListApi,
    getSingleUserUrl, updateUserUrl
} from "../api";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import axios from "axios";
import {getToken} from "../../../request";
import {updateProductUrl} from "../../products/api";

export const userListFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    };
    const {data} = await axios.get(userListApi(), config);
    return data;
};
export const addUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const {data} = await axios.post(addUserApi(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
        notification.success({message: data.message || "Success"});
    } else {
        notification.error({message: data.message || "Error"});
    }
    return data;
};

export const getSingleUserFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.get(getSingleUserUrl(id), getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const updateUserFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateUserUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
