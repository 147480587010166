import React, {useState} from "react";
import {useDispatch} from "react-redux";
import AddCustomerDrawer from "../../components/addCustomerDrawer";

const $ = window.$;
const TopMenu = (props) => {
    let dispatch = useDispatch()
    let [customerState, setCustomerState] = useState({
        visible: false,
        lead: {},
    });
    let {currentUser} = props;
    const logoutFxn = () => {
        localStorage.clear();
        setTimeout(() => {
            dispatch({
                type: "SET_CURRENT_USER",
                user: {},
            });
            window.location.href = "/login";
        }, 100);
    };

    const events = {
        hideFollowUp: () => {
            setCustomerState({
                visible: false,
            });
        },
    };

    return (
        <>
            <div className="header">
                <div className="header-left active">
                    <a href="/" className="logo logo-normal">
                        <img src={'../assets/logos/thrones_b_nd_w.png'} alt=""/>
                    </a>
                    <a href="/" className="logo logo-white">
                        <img src={'../assets/logos/thrones_small.png'} alt=""/>
                    </a>
                    <a href="/" className="logo-small">
                        <img src={'../assets/logos/thrones_small.png'} alt=""/>
                    </a>
                    <a id="toggle_btn">
                        <i data-feather="chevrons-left" className="feather-16"/>
                    </a>
                </div>
                <a id="mobile_btn" className="mobile_btn" href="#sidebar">
					<span className="bar-icon">
						<span/>
						<span/>
						<span/>
					</span>
                </a>
                <ul className="nav user-menu">
                    <li className="nav-item nav-searchinputs">
                    </li>
                    {customerState.visible ? (
                        <AddCustomerDrawer
                            onClose={events.hideFollowUp}
                            onSubmit={() => {
                                events.hideFollowUp();
                            }}
                            visible={customerState.visible}
                        />
                    ) : null}

                    <li className="nav-item dropdown nav-item-box">
                    </li>
                    <li className="nav-item nav-item-box">
                    </li>
                    <li className="nav-item dropdown has-arrow main-drop">
                        <a className="dropdown-toggle nav-link userset"
                           data-bs-toggle="dropdown">
							<span className="user-info">
								<span className="user-letter">
								</span>
								<span>
									<span className="user-name"
                                          style={{textTransform: "capitalize"}}>{currentUser.name}</span>
									<span className="user-role">{currentUser.userType}</span>
								</span>
							</span>
                        </a>
                        <div className="dropdown-menu menu-drop-user">
                            <div className="profilename">
                                <div className="profileset">
                                    <span className="status online"/>
                                    <i className="me-2"
                                       data-feather="user"/>
                                    <div className="profilesets">
                                        <h6 style={{textTransform: "capitalize"}}>{currentUser.name}</h6>
                                        <h5>{currentUser.userType}</h5>
                                    </div>
                                </div>
                                <a className="dropdown-item" href="/changePassword">
                                    <i className="me-2"
                                       data-feather="key"
                                    /> Change Password</a>
                                <a className="dropdown-item" onClick={logoutFxn}><i
                                    className="me-2 fa fa-sign-out-alt"/>Logout</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="dropdown mobile-user-menu">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                       aria-expanded="false"><i className="fa fa-ellipsis-v"/>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <span className={'TopMenuName'}>
                            <i className="fa fa-user"/> {currentUser.name}</span>
                        <a className="dropdown-item" href="/changePassword">Change Password</a>
                        <a className="dropdown-item" onClick={logoutFxn}>Logout</a>
                    </div>
                </div>
            </div>

        </>
    );
};
export default TopMenu;
