import { apiUrl } from "../../../setting";

export const pendingKotUrl = () => {
    return '/getPendingKot'
}
export const updateKotStatusUrl = () => {
    return '/updateKotStatus'
}

export const getAllOrdersUrl = () => {
    return `${apiUrl}/orderList`;
};

export const editOrderUrl = () => {
    return `${apiUrl}/updateOrder`;
};

//edit kot and save order apis from order list  (23/5/2024 update)
export const editKotOfDoneOrderURL = () => {
    return apiUrl + "/editKotOfDoneOrder";
};

export const saveOrderOfDoneOrderUrl = () => {
    return apiUrl + "/saveOrderForDoneOrder";
};
