import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  
  InputBox,
  Card,
  Modal,
  notification,
  calculation,
  Table,
  Tooltip
} from "../../components/Elements/appUtils";
import Image from "../../add-button.png"

import { getSaleProductListUrl } from "./api";
import { getToken } from "../../request";
import TableMain from "../../components/Elements/Table";
// import {getStockUrl, getbatchRecordByProductIdUrl} from "./api";
// import {fetchRequestedProductReport} from "../stockRequest/api";
import _ from "lodash";
import async from "async";

import AddSaleProductDrawer from "./addSaleProductDrawer"

let initState = {
  productId: "",
  productName: "",
  quantity: 1,
  price: "",
  salePrice: "",
  inclusivePrice: "",
  pUnit: "",
  hsn: "",
  subTotal: "",
  discount: "",
  discountAmount: "",
  expiryDate: "",
  manufacturingDate: "",
  batchNo: "",
  batchId: "",
  taxableAmount: "",
  igstAmount: "",
  cgstAmount: "",
  sgstAmount: "",
  igstTax: "",
  gst: "",
  uom: "",
  totalAmount: "",
  gstAmount: "",
  netAmount: "",
  stateOfSupply: "",
  customerId: "",
  reportType: "Inclusive",
  transactionType: "",
  BatchQunatity: "",
};

const ReportMappingComponent = ({
  item,
  index,
  selectProductSubmit,
  ProductReport,
}) => {
  const [enteredQuantity, setEnteredQuantity] = useState("");

  let show;

  if (
    ProductReport.find((x) => {
      return x.productId == item.productId;
    })
  ) {
    show = false;
  } else {
    show = true;
  }

  return (
    <>
      <tr key={item.id}>
        <td>{index + 1}</td>
        <td>{item.productName}</td>
        <td>{item.hsn}</td>
        <td>{item.uom}</td>
        <td>{item.quantity}</td>
        <td>
          <input
            className={"form-control"}
            type="number"
            value={enteredQuantity || item.quantity}
            onChange={(e) => setEnteredQuantity(e.target.value)}
          ></input>
        </td>
        <td>
          {show == true ? (
            <a
              onClick={() => {
                selectProductSubmit(
                  { target: { value: item.productId } },
                  "productId",
                  enteredQuantity || item.quantity
                );
              }}
              className="btn btn-info btn-xs"
            >
              Select batch
            </a>
          ) : (
            <p>Added To Cart</p>
          )}
        </td>
      </tr>
    </>
  );
};

const MedDataMapingComponent = ({
  item,
  index,
  selectProductSubmit,
  ProductReport,
}) => {
  const [enteredQuantity, setEnteredQuantity] = useState("");

  let show;

  if (
    ProductReport.find((x) => {
      return x.productId == item.productId;
    })
  ) {
    show = false;
  } else {
    show = true;
  }

  return (
    <>
      <tr key={item.id}>
        <td>{index + 1}</td>
        <td>{item.productName}</td>
        <td>{item.dosage}</td>
        <td>{item.frequency}</td>
        <td>{item.remarks}</td>
        <td>{item.whentotake}</td>
        <td>
          <input
            className={"form-control"}
            type="number"
            value={enteredQuantity || item.quantity}
            onChange={(e) => setEnteredQuantity(e.target.value)}
          ></input>
        </td>
        <td>
          {show == true ? (
            <a
              onClick={() => {
                selectProductSubmit(
                  { target: { value: item.productId } },
                  "productId",
                  enteredQuantity || item.quantity
                );
              }}
              className="btn btn-info btn-xs"
            >
              Select batch
            </a>
          ) : (
            <p>Added To Cart</p>
          )}
        </td>
      </tr>
    </>
  );
};

const ItemListComponent = (props) => {
  let {
    visitData,
    ProductReport,
    updateProductReport,
    statesAreEqual,
    stateOfSupply,
    companyState,
    requestId,
    visitId,
    setIsModal2Open,
    isModal2Open,
  } = props;
  const [stock, setStock] = useState([]);
  // const userType = getItemLocalstorage("user")["userType"];

  let [Report, setReport] = useState([]);
  let [medData, setMedData] = useState([]);
  let [k, setk] = useState("");
  let [visible, setVisible] = useState(false);
  let [Batch, setBatch] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestedMedModal, setRequestedMedModal] = useState(false);

  const [visibleBatchModal, setVisibleBatchModal] = useState(false);
  let [productState, setProductState] = useState(initState);
  let [productList, setProductList] = useState([]);

  const fetchStock = async () => {
      try {
          const response = await axios.get(getSaleProductListUrl(), getToken());
          setStock(response.data.data);
      } catch (error) {
          console.error("Error getting the data:", error);
      }
  };
  console.log(stock, "stocks")

  useEffect(() => {
    console.log(ProductReport);
    setProductList(ProductReport);
  }, [ProductReport]);

  useEffect(() => {
    if (visitId == null) {
      setRequestedMedModal(false);
    } else {
      setRequestedMedModal(true);
    }
  }, [visitId]);

  const handleBatchSelect = (Batch) => {
    // setSelectedBatch(Batch);
    let obj = {
  
   
      price: Batch.salePrice ? Batch.salePrice : 0,
    
      salePrice: Batch.salePrice,
    };
    setIsModalOpen(false);
    setProductState((prevState) => {
      return {
        ...prevState,
        ...obj,
      };
    });
  };

  

  useEffect(() => {
    reCalculateAmount().then(() => {
      setk(new Date().toString());
    });
  }, [
    productState.productId,
    productState.reportType,
    productState.price,
    productState.quantity,
    productState.discount,
    productState.gst,
  ]);

  const reCalculateAmount = () => {
    return new Promise((resolve) => {
      calculation({ ...productState }, stateOfSupply, companyState).then(
        (resp) => {
          setProductState({
            ...productState,
            ...resp,
          });
        }
      );
      return resolve("done");
    });
  };

  const StateChangesRecalculate = () => {
    // Assuming productState is an array
    let cloneData = _.clone(ProductReport);
    let newArr = [];
    _.each(cloneData, async (item) => {
      let resp = await calculation(item, stateOfSupply, companyState);
      newArr.push(resp);
    });
    // setProductList(newArr);
    updateProductReport(newArr);
  };

  // const fetchBatch = async () => {
  //     let productId = productState.productId;
  //     try {
  //         const response = await axios.get(getbatchRecordByProductIdUrl(), {
  //             params: {productId},
  //             ...(await getToken()),
  //         });
  //         setBatch(response.data.data);
  //     } catch (error) {
  //         console.error("Error getting the data:", error);
  //     }
  // };
  // useEffect(() => {
  //     fetchBatch();
  // }, [productState.productId]);

  useEffect(() => {
      // fetchProduct();
      fetchStock();
  }, []);

  const onSubmit = () => {
    if (!productState.productId) {
      notification.warning({ message: "Please Enter Product" });
      return;
    }
    if (!productState.price) {
      notification.warning({ message: "Please Enter Price" });
      return;
    }
    if (!productState.quantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    if (productState.discount > 100) {
      notification.warning({
        message: "Please Enter A Valid Discount Percentage",
      });
      return;
    }
    // // if (!productState.batchId) {
    // //   notification.warning({ message: "Please Choose Batch" });
    // //   return;
    // // }
    // if (productState.BatchQunatity < productState.quantity) {
    //   notification.warning({
    //     message: "Entered Quantity Cannot Be Greater Than Choose Batch",
    //   });
    //   return;
    // }

    if (
      ProductReport.find((x) => {
        return (
          x.batchNo === productState.batchNo &&
          x.productId === productState.productId
        );
      })
    ) {
      notification.warning({
        message: "Product Already Exist With Same Batch",
      });
      return;
    }

    const updatedProductReport = [
      ...ProductReport,
      { ...productState, serialNo: ProductReport?.length + 1 },
    ];
    updateProductReport(updatedProductReport);

    setProductState(initState);
  };

  const handleChange1 = (e, fieldName) => {
    const { value } = e.target;
    let obj = {};
    if (fieldName == "productId") {
      const selectedProduct = stock.find(
        (prod) => prod._id === value
      );

      obj = {
        productName: selectedProduct.name,
        pUnit: selectedProduct.unitCode,
        hsn: selectedProduct.hsnCode,
        gst: selectedProduct.gst,
        price: selectedProduct.price,
        uom: selectedProduct.unitCode,
      };
      setIsModalOpen(true);
    }
    setProductState({
      ...productState,
      [fieldName]: value,
      stateOfSupply: stateOfSupply,
      ...obj,
    });
  };

  const _selectProductSubmit = (e, fieldName, batchQuantity) => {
    if (!batchQuantity) {
      notification.warning({ message: "Please Enter Quantity" });
      return;
    }
    setVisibleBatchModal(true);
    const { value } = e.target;
    let obj = {};
    if (fieldName == "productId") {
      const findedProduct = stock.find((prod) => prod.productId._id === value);

      obj = {
        quantity: batchQuantity,
        productName: findedProduct.productId.name,
        pUnit: findedProduct.productId.unitCode,
        hsn: findedProduct.productId.hsnCode,
        gst: findedProduct.productId.gst,
        uom: findedProduct.productId.unitCode,
      };
    }

    setProductState({
      ...productState,
      [fieldName]: value,
      stateOfSupply: stateOfSupply,
      ...obj,
    });

    // setk(new Date().toString());
  };

  let Cgst = {
    title: "CGST",
    dataIndex: "cgstAmount",
    key: "cgstAmount",
    render: (text, record) => (
      <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
    ),
  };

  let Sgst = {
    title: "SGST",
    dataIndex: "sgstAmount",
    key: "sgstAmount",
    render: (text, record) => (
      <span>
        {record.gst / 2}% (Rs.{text}){" "}
      </span>
    ),
  };

  let Igst = {
    title: "IGST",
    dataIndex: "igstTax",
    key: "igstTax",
    render: (text, record) => (
      <span>
        {text}% (Rs.{record.igstAmount}){" "}
      </span>
    ),
  };

  let NetAmount = {
    title: "Net Amount",
    dataIndex: "netAmount",
    key: "netAmount",
    render: (text) => `Rs. ${text}`,
  };

  let action = {
    title: "Action",
    key: "delete",
    render: (text, record, index) => {
      return (
        <>
          <a className="empty_btn" onClick={() => handleDelete(index)}>
            <i className="fa fa-trash-alt"></i>
          </a>
        </>
      );
    },
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Type",
      dataIndex: "reportType",
      key: "reportType",
    },
    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
    },
 
    {
      title: "Qty.",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "UoM",
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: "Rate",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => `Rs.${text}`,
    },
    {
      title: "Dis(%)",
      dataIndex: `discount`,
      key: "discount",
      render: (text) => <span>{text}%</span>,
    },
    {
      title: "Dis Amt.",
      dataIndex: `discountAmount`,
      key: "discountAmount",
      render: (text) => <span>Rs.{text}</span>,
    },
    {
      title: "Taxable Amount",
      dataIndex: "taxableAmount",
      key: "taxableAmount",
      render: (text) => `Rs. ${text}`,
    },
  ];

  const handleDelete = (index) => {
    const updatedProductReport = [...ProductReport];
    updatedProductReport.splice(index, 1);
    updateProductReport(updatedProductReport);
  };

  const getColumns = () => {
    if (stateOfSupply == companyState) {
      columns.push(Cgst, Sgst, NetAmount, action);
    } else {
      columns.push(Igst, NetAmount, action);
    }
    return columns;
  };

  useEffect(() => {
    StateChangesRecalculate();
  }, [stateOfSupply, statesAreEqual, k]);

  // const fetchRequestedProductReport1 = async () => {
  //     try {
  //         let config = {
  //             params: {requestId},
  //             ...(await getToken()),
  //         };
  //         const response = await axios.get(fetchRequestedProductReport(), config);
  //         setReport(response.data.data);
  //     } catch (error) {
  //         console.error("Error getting the data:", error);
  //     }
  // };

  // const fetchPrescribedMedicine = (params) => {
  //   return new Promise(async (resolve) => {
  //     try {
  //       params.requestedMedId = requestedMedId;
  //       const data = await fetchPrecription({ ...params });
  //       setMedData(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   });
  // };

  // useEffect(() => {
  //     if (requestId) {
  //         fetchRequestedProductReport1();
  //     }
  // }, [requestId]);

  // useEffect(() => {
  //   if (requestedMedId) {
  //     setRequestedMedModal(true);
  //     fetchPrescribedMedicine();
  //   }
  // }, [requestedMedId]);
console.log(stock, "stocks")
  return (
    <>
      <Card title={"Select Items"} size={"small"}>
        <div className={"item-flex"}>
        <InputBox title={"Product"} className={"flex2"}>
              <div className="d-flex align-items-center">
                <select
                  style={{ height: "40px", width: "150px" }}
                  className="form-control flex-grow-1"
                  value={productState.productId}
                  onChange={(e) => handleChange1(e, "productId")}
                  required
                >
                  <option value="">Select Product</option>
                  {stock.map((stock) => (
                    <option
                      key={stock._id}
                      value={stock._id}
                    >
                      {stock.name}
                    </option>
                  ))}
                </select>
                {/* <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Select a product"
      filterOption={false}
      autoClearSearchValue={true}
      allowClear={true}
      value={productState.productId.name}
      showArrow={true}
     
      onSearch={handleSearch}
      onChange={(e) => handleChange1(e, "productId")}
    >
      {filteredStock.map((stockItem) => (
        <Option key={stockItem.productId._id} value={stockItem.productId._id}>
          {stockItem.productId.name}
        </Option>
      ))}
    </Select> */}

                <Tooltip title={"Add Product"}>
                  <a className={"plus_btn"} style={{marginLeft: "6px", marginTop:"4px"}} onClick={() => setVisible(true)}>
                  <img src={Image} style={{height:"20px", width: "25px"}}></img>
                  </a>
                </Tooltip>
              </div>
            </InputBox>
            {visible ? (
              <AddSaleProductDrawer
                visible={visible}
                onSubmit={() => fetchStock()}
                onClose={() => setVisible(false)}
              />
            ) : null}
          <InputBox title={"Type"}>
              <select
                style={{ height: "40px" }}
                className="form-control"
                value={productState.reportType}
                onChange={(e) => handleChange1(e, "reportType")}
                required
              >
                <option>Select Tax Type</option>
                <option>Inclusive</option>
                <option>Exclusive</option>
              </select>
            </InputBox>
          <InputBox title={"Sale Price"}>
            <input
              className={"form-control"}
              type="number"
              style={{ height: "40px" }}
              placeholder="Sale Price"
              value={productState.price}
              onChange={(e) => handleChange1(e, "price")}
            />
          </InputBox>
          <InputBox title={"Qty."}>
            <input
              className={"form-control"}
              type="number"
              style={{ height: "40px" }}
              value={productState.quantity}
              placeholder="Quantity"
              onChange={(e) => handleChange1(e, "quantity")}
            />
          </InputBox>
          <InputBox title={"Dis(%)"}>
            <input
              className={"form-control"}
              type="number"
              style={{ height: "40px" }}
              value={productState.discount}
              placeholder="Discount"
              onChange={(e) => handleChange1(e, "discount")}
              max="100"
            />
          </InputBox>
          <InputBox title={"GST(%)"}>
            <input
              style={{ height: "40px", color: "black" }}
              disabled
              className={"form-control"}
          
              type="text"
              value={productState.gst}
              placeholder="GST"
              onChange={(e) => handleChange1(e, "gst")}
            />
          </InputBox>



            <a
              className="addBtnValue"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
                <img src={Image} style={{height:"20px", width: "30px"}}></img>
            </a>
        
        </div>
      </Card>
      <div key={ProductReport?.length} className="mb-4">
        <div className="d-flex">
          <h4 className="m-2">Items List</h4>
        </div>
        <div key={productList} >
          {" "}
          {/* Adjust the width as needed */}
          <Table
            dataSource={productList}
            columns={getColumns()}
            key={JSON.stringify(productList)}
          />
        </div>
       
      </div>
    
      {/* {isModalOpen && (
        <Modal
          visible={isModalOpen}
          // closable={false}
          title={"Choose Batch"}
          onClose={() => setIsModalOpen(false)}
        >
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td>Batch Number</td>
                  <td>Mfg Date</td>
                  <td>Exp Date</td>
                  <td>Quantity</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {Batch.map((item) => (
                  <tr key={item.id}>
                    <td>{item.batchNo}</td>
                    <td>{item.manufacturingDateString}</td>
                    <td>{item.expiryDateString}</td>
                    <td>{item.quantity}</td>
                    <td>
                      <button
                        className="btn btn-info btn-xs"
                        onClick={() => handleBatchSelect(item)}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      {isModal2Open && (
        <Modal
          title={"Requested Products List"}
          width={"50%"}
          visible={isModal2Open == true}
          onClose={() => setIsModal2Open(false)}
        >
          <table className="table table-bordered">
            <thead>
              <tr>
                <td>Sr no.</td>
                <td>Product Name</td>
                <td>HSN</td>
                <td>UOM.</td>
                <td>Requested Qty.</td>
                <td>Qty.</td>
              </tr>
            </thead>
            {Report.map((item, index) => (
              <ReportMappingComponent
                ProductReport={ProductReport}
                item={item}
                index={index}
                selectProductSubmit={_selectProductSubmit}
              />
            ))}
          </table>
        </Modal>
      )} */}



    </>
  );
};
export default ItemListComponent;
