import React, { useEffect, useRef, useState } from "react";
import { Table, Tooltip, Popconfirm } from "../../components/Elements/appUtils";
import { notification } from "../../components/Elements/appUtils";
import { fetchProduct, fetchTopSelling } from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { deleteProductUrl } from "./api";
import { updateProductStatusUrl } from "./api";

function ProductListDashboard() {
  let tableRef = useRef();
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        const data = await fetchTopSelling();
        resolve(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const search = async () => {
    if (tableRef.current) {
      tableRef.current.reload();
    }
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "_id",
      key: "_id",
    },

    {
      title: "Quantity",
      dataIndex: "count",
      key: "count",
    },
  ];

  const getColumns = () => {
    let columnArr = [];
    _.each(columns, (item) => {
      if (!item.hide) {
        columnArr.push(item);
      }
    });
    return columnArr;
  };

  return (
    <PageHeader title={"Top 15 Selling Products"}>

      <div className="card mb-0">
        <div className="table-responsive">
          <Table
            apiRequest={apiRequest}
            columns={getColumns()}
            ref={tableRef}
          />
        </div>
      </div>
    </PageHeader>
  );
}

export default ProductListDashboard;
