import {apiUrl} from "../../../setting";

export const addUserApi = () => {
    return `${apiUrl}/api/add-user`;
};

export const userListApi = () => {
    return `${apiUrl}/api/user-list`;
};
export const getSingleUserUrl = (id) => {
    return apiUrl + "/getSingleUser/" + id;
};

export const updateUserUrl = () => {
    return `${apiUrl}/api/updateUser`;
};
