import { hideLoader, showLoader } from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import axios from "axios";
import { changepasswordUrl } from "../api";
import { getToken } from "../../../request";
export const changepasswordFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let { data } = await axios.post(changepasswordUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    } else {
      notification.success({
        message: data.message || "Success",
      });
    }
    return data;
  };