import React, {useEffect, useRef, useState} from "react";
// import { customAxios as axios, getToken } from "../../request";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Form,
    InputBox,
    Popconfirm,
    Table,
    TableButton,
    displayDate,
    longDisplayDate,
    notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {appAxios as axios, getToken} from "../../request";
import {
    deleteCouponFxn,
    editCouponFxn,
    fetchCoupon,
    getSingleCouponFxn,
} from "./actions";
import {addCouponUrl} from "./api";

function AddCoupon(props) {
    let tableRef = useRef();
    let dispatch = useDispatch();

    const {getFieldValue} = props.form;

    const [formData, setFormData] = useState({
        name: "",
    });

    const [categoryFile, setCategoryFile] = useState("");
    const [couponId, setCouponId] = useState("");

    useEffect(() => {
        checkCouponId();
    }, []);
    const navigate = useNavigate();
    const handleChange = (e, fieldName) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    const checkCouponId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        let id = searchParams.get("_id");
        if (id) {
            setCouponId(id);
            getSingleCoupon(id);
        }
    };

    const getSingleCoupon = async (id) => {
        let {data, success} = await dispatch(getSingleCouponFxn(id), getToken());
        console.log(data, "ddtdtdtdtt");
        if (success) {
            // setCouponData({name: data.name, amount: data.amount});
            setTimeout(() => {
                props.form.setFieldsValue({
                    mobileNo: data.mobileNo,
                    discount: data.discount,
                });
            }, 300);
        }
    };
    let events = {};
    const handleSubmit = async (e) => {
        const {form} = props;

        e.preventDefault();
        console.log(formData, "formdata");
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (valData.discount > 25) {
                    notification.warning({message: "Discount cannot be more than 25%"});
                    return;
                }
                console.log(valData, "dddddddddddddddd");
                valData.couponId = couponId;
                let response = await dispatch(editCouponFxn(valData));
                console.log(response, "dddddddddddddddd");
                // if (response.success) {
                //   notification.success({
                //     message: response.message || "Success",
                //   });
                // } else {
                //   notification.error({ message: response.data.message });
                // }
            } else {
                if (!valData.discount) {
                    notification.warning({message: "Please Enter Discount"});
                    return;
                }
                if (!valData.mobileNo) {
                    notification.warning({message: "Please Enter MobileNo"});
                    return;
                }
            }
        });

        // Send the data to the backend using Axios
    };

    let inputTypes = {
        fields: [
            {
                key: "discount",
                label: "Discount in Percentage",
                span: "col-md-4",
                type: "number",
                required: true,
            },
            {
                key: "mobileNo",
                label: "Mobile Number",
                span: "col-md-4",
                required: true,
            },

            // {
            //   key: "status",
            //   label: "Status",
            //   span: "col-md-4",
            //   required: true,

            //   type: "select",
            //   options: ["Active", "Used", "Expired"],
            // },
        ],
    };

    return (
        <PageHeader title={"Edit Coupon"}>
            <Form onSubmit={handleSubmit}>
                <div className="form-elements-wrapper">
                    <div className="card-body">
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return !item.hidden ? (
                                    <div
                                        className={`${item.span ? item.span : "col-md-6"}`}
                                        key={key}
                                    >
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                ) : null;
                            })}
                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">
                    Update
                </button>
            </Form>
        </PageHeader>
    );
}

export default Form.create()(AddCoupon);
