import React, {useEffect, useRef, useState} from "react";
import {arraySorter, Form, notification, Table} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import {fetchProduct, GroupedProductList} from "../products/actions";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import moment from "moment";
import {tableListFxn} from "../pos/actions";
import {userListFxn} from "../userManager/actions";

function GroupedProductsList(props) {
    let tableRef = useRef();
    const { getFieldValue } = props.form;
    let [products, setProducts] = useState([]);

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (getFieldValue('fromD') && getFieldValue('toD')) {
                    params.fromD = getFieldValue('fromD')
                    params.toD = getFieldValue('toD')
                }else {
                    let currentDate = moment();
                    params.fromD = moment(currentDate).startOf('month').toDate();
                    params.toD = moment(currentDate).endOf('month').toDate();
                }
                if (getFieldValue('productIds')) {
                    params.productIds = getFieldValue('productIds')
                }
                // if (getFieldValue('tableName')) {
                //     let table = tableList.find(table => table.name === getFieldValue('tableName'));
                //     const keysArray = table.tables.map(item => item.key);
                //     console.log(keysArray,"tables array")
                //     params.tableNameArray = keysArray
                // }
                // if (getFieldValue('userId')) {
                //     params.userId = getFieldValue('userId')
                // }
                const data = await GroupedProductList({...params});
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const getProducts = async (params={}) => {
        params.results = 10000
        const data = await fetchProduct({...params});
        setProducts(arraySorter(data.data.data))
    }
    //
    // let [tableList, setTableList] = useState([])
    // let [userList, setUsers] = useState([])
    // let loadTables = async () => {
    //     let {data} = await tableListFxn();
    //     setTableList(data);
    // }
    // let loadUsers = async () => {
    //     const {data} = await userListFxn();
    //     setUsers(data);
    // }

    useEffect(() => {
        getProducts()
        let currentDate = moment();
        let firstDayOfMonth = moment(currentDate).startOf('month');
        let lastDayOfMonth = moment(currentDate).endOf('month');
        props.form.setFieldsValue({
            fromD: firstDayOfMonth,
            toD: lastDayOfMonth,
        });
        // loadTables() //4-6-24
        // loadUsers() //4-6-24
    }, [])

    useEffect(() => {
        let fromDate = getFieldValue('fromD');
        let toDate = getFieldValue('toD');
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        const timeDifference = Math.abs(toDateObj.getTime() - fromDateObj.getTime());
        const differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (differenceInDays > 31) {
            notification.warning({
                message: 'Difference Between From Date And To Date Cannot Exceeds 1 Month'
            });
            props.form.setFieldsValue({
                toD: ''
            })
        }
    }, [getFieldValue('fromD'), getFieldValue('toD')])


    const refreshTable = () => {
        tableRef.current.reload();
    };
    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "_id",
            key: "_id",
            render: (text, record, index) => record.productName,

        },
        {
            title: "Quantity",
            dataIndex: "count",
            key: "count",
        },
    ];

    let inputTypes = {
        fields: [
            {
                key: "fromD",
                label: "From Date",
                span: "col-md-3",
                type: "date",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        fromD: x,
                    });
                },
            },
            {
                key: "toD",
                label: "To Date",
                span: "col-md-3",
                type: "date",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        toD: x,
                    });
                },
            },
            // {
            //     key: "tableName",
            //     label: "Select Table",
            //     placeholder: "Choose Table",
            //     type: "select",
            //     showStar: true,
            //     span: "col-md-2",
            //     options: tableList,
            //     showSearch: true,
            //     keyAccessor: (x) => x.name,
            //     valueAccessor: (x) => `${x.name}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             tableName: x,
            //         });
            //     },
            // },
            // {
            //     key: "userId",
            //     label: "Select User",
            //     placeholder: "Choose User",
            //     type: "select",
            //     showStar: true,
            //     span: "col-md-2",
            //     options: userList,
            //     showSearch: true,
            //     keyAccessor: (x) => x._id,
            //     valueAccessor: (x) => `${x.name}`,
            //     onChange: (x) => {
            //         props.form.setFieldsValue({
            //             userId: x,
            //         });
            //     },
            // },
            {
                key: "productIds",
                label: "Select Products",
                placeholder: "Choose Products",
                type: "select",
                showStar: true,
                span: "col-md-6",
                options: products,
                multiple: true,
                showSearch: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        productIds: x,
                    });
                },
            },
        ]

    };

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.hide) {
                columnArr.push(item);
            }
        });
        return columnArr;
    };

    return (
        <PageHeader title={"Monthly Reporting"}>
            <div className="row mb-3">
                <Form onSubmit={refreshTable}>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-elements-wrapper">
                                <div className={"row"}>
                                    {inputTypes.fields.map((item, key) => {
                                        return (
                                            <div
                                                className={`${item.span ? item.span : "col-md-6"}`}
                                                key={key}
                                            >
                                                <GetEachFormFields {...props.form} item={item}/>
                                            </div>
                                        );
                                    })}
                                    <button className="btn btn-info col-md-1 ms-3 h-25"
                                            onClick={refreshTable}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="card">
                <div className="table-responsive">
                    <Table
                        apiRequest={apiRequest}
                        columns={getColumns()}
                        ref={tableRef}
                    />
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(GroupedProductsList);
