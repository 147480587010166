import React from "react";

import { Modal } from "../../components/Elements/appUtils";

import AddSaleProduct from "./addSaleProduct";

const AddSaleProductDrawer = (props) => {
  let { visible, onClose,onSubmit } = props;

  console.log(onSubmit, "onsubmit")
  return (
    <Modal
      width={"75%"}
      visible={visible}
      onClose={onClose}
    
    >
    <AddSaleProduct onClose={onClose} onSubmit={onSubmit}/>
    </Modal>
    
  );
};
export default AddSaleProductDrawer;
