import React, {useEffect, useRef, useState} from "react";
import {Modal, Table, DefaultTablePagination, DatePicker, InputBox, notification} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import moment from "moment";
import {editKotOfSaveOrderFxn, editOrderFxn, fetchAllOrder, saveOrderFromDoneOrderFxn} from "./actions";
import {getItemLocalstorage, getVegStatus, PaymentType} from "../../components/_utils/_utils";
import {getOldKotByOrderIdFxn, getPrintProductsFxn, tableListFxn} from "../pos/actions";
import PrintDesign from "../print/printDesign";
import {useReactToPrint} from "react-to-print";
import {useDispatch} from "react-redux";
import {paymentGroupByPaymentMethodFxn} from "../dashboard/action";
import {userListFxn} from "../userManager/actions";

function OrderList() {
    const userType = getItemLocalstorage("user")["userType"];
    const userId = getItemLocalstorage("user")["_id"];
    let dispatch = useDispatch();
    const componentRef = useRef();
    let tableRef = useRef();
    let [oldKots, setOldKots] = useState({
        total: 0,
        data: [],
    });
    let [isModalOpen, setIsModalOpen] = useState(false);
    let [isEditOrderModalOpen, setIsEditOrderModalOpen] = useState(false);
    let initPaymentMode = [
        {name: "Card", value: 0},
        {name: "Cash", value: 0},
        {name: "UPI", value: 0},
        {name: "Total", value: 0}
    ]
    const [paymentData, setPaymentData] = useState(initPaymentMode)
    const initialEditData = {
        totalAmount: 0,
        discount: 0,
        cgstAmount: 0,
        sgstAmount: 0,
        serviceTaxAmount:0,
        netAmount:0,
        cashDiscount: 0,
        finalAmount: 0,
        customerPaid: 0,
        cashAmount: 0,
        upiAmount: 0,
        cardAmount: 0,
    }
    const [orderData, setOrderData] = useState(initialEditData)
    const [orderNumber, setOrderNumber] = useState('')

    let [data, setData] = useState({
        orderNo: "",
        totalAmount: "",
        cgstAmount: "",
        sgstAmount: "",
        serviceTaxAmount: "",
        netAmount: "",
        orderId: "",
        discountPer:0
    });
    let [dates, setDates] = useState({
        fromDate: "",
        toDate: "",
    });

    let [singleOrderData, setSingleOrderData] = useState({});
    let [choosePaymentType, setChoosePaymentType] = useState("");
    let [orderId, setOrderId] = useState("");
    let [editKotModal, setEditKotModal] = useState(false);
    let [refreshView, setRefrestView] = useState(false);
    let [kotId, setKotId] = useState("");
    let [editKotProducts, setEditKotProducts] = useState([]);
    let [editdata, seteditData] = useState({});
    let [tableNo, setTableNo] = useState('');
    let [viewEditKot, setViewEdit] = useState(false);
    let [tableFilterData, setTableFilterData] = useState([]);
    let [tableName, setTableName] = useState('');
    let [userName, setUserName] = useState('');
    let [tableList, setTableList] = useState([])
    let [userList, setUsers] = useState([])
    useEffect(() => {
        if (tableName) {
            let table = tableList.find(table => table.name == tableName);
            const keysArray = table.tables.map(item => item.key);
            setTableFilterData(keysArray)
        }else{
            setTableFilterData([])
        }
    }, [tableName]);
    useEffect(() => {
        if (kotId) {
            const kot = oldKots.data.find((kot) => kot._id == kotId);
            if (kot) {
                setEditKotProducts(kot.products);
                setTableNo(kot.tableNo)
            }
        }
    }, [kotId]);

    let events = {

        incEditQty: (data) => {
            let clonePro = _.clone(editKotProducts);
            let findPro = _.find(clonePro, (item) => {
                return (
                    item.productId._id == data.productId._id && item.price == data.price
                );
            });
            if (findPro) {
                findPro.quantity = findPro.quantity + 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            }
            setEditKotProducts(clonePro);
        },

        desEditcQty: (data) => {
            let clonePro = _.clone(editKotProducts);
            let findPro = _.find(clonePro, (item) => {
                return (
                    item.productId._id == data.productId._id && item.price == data.price
                );
            });
            if (findPro) {
                findPro.quantity = findPro.quantity - 1;
                findPro.totalAmount = findPro.quantity * parseFloat(findPro.price);
            }
            if (findPro.quantity == 0) {
                editKotProducts = _.reject(clonePro, (item) => {
                    return (
                        item.productId._id == data.productId._id && item.price == data.price
                    );
                });
                setEditKotProducts(editKotProducts);
            } else {
                setEditKotProducts(clonePro);
            }
        },
    };

    let handleEditkot = async (editKotProducts, kotSubmitType) => {
        editdata.products = editKotProducts;
        editdata.tableNo = tableNo;
        editdata.kotId = kotId;
        editdata.kotSubmitType = kotSubmitType;
        editdata.serviceTaxIncluded = true;
        editdata.orderId = data.orderId
        let x = await dispatch(editKotOfSaveOrderFxn(editdata));
        if (x && x.success == true) {
            getOldKotData()
            setEditKotModal(false)
            setIsModalOpen(false)
            tableRef.current.reload()
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    let loadOrderData = async (orderId) => {
        let params = {orderId, count: 1000, results: 1000};
        let resp = await dispatch(getPrintProductsFxn(params));
        setSingleOrderData(resp);
    };

    let oldOrderData = async (orderId) => {
        let params = {orderId, count: 1000, results: 1000};
        let resp = await dispatch(getPrintProductsFxn(params));
        setOrderNumber(resp.orderNo)
        setOrderData({
            totalAmount: resp.totalAmount,
            discount: resp.discount,
            cgstAmount: resp.cgstAmount,
            sgstAmount: resp.sgstAmount,
            serviceTaxAmount:resp.serviceTaxAmount,
            netAmount:resp.netAmount,
            cashDiscount: resp.cashDiscount,
            finalAmount: resp.finalAmount,
            customerPaid: resp.customerPaid,
            cashAmount: resp.cashAmount,
            upiAmount: resp.upiAmount,
            cardAmount: resp.cardAmount,
        })
    };

    useEffect(() => {
        if (singleOrderData && singleOrderData._id) {
            handlePrint();
        }
    }, [singleOrderData]);

    const loadPaymentData = async (params = {}) => {
        if (dates.fromDate && dates.toDate) {
            params.fromDate = dates.fromDate
            params.toDate = dates.toDate
        }
        if (choosePaymentType) {
            params.paymentType = choosePaymentType
        }
        if (tableFilterData.length > 0 ){
            params.tableNo = tableFilterData;
        }
        if (userName){
            params.userId = userName;
        }
        let finalArr = []
        const {data} = await paymentGroupByPaymentMethodFxn({...params});
        let cloneDoc = _.cloneDeep(initPaymentMode);
        _.each(cloneDoc, (item) => {
            let findVal = _.find(data, (doc) => {
                return doc._id == item.name.toLowerCase()
            })

            if (findVal) {
                finalArr.push({
                    name: item.name,
                    value: findVal.totalAmount,
                    ...findVal
                })
                // item.value = findVal.totalAmount
            }
        })
        setPaymentData([data[0]])
    };

    useEffect(() => {
        loadPaymentData()
        loadTables()
        loadUsers()
    }, [])

    const getOldKotData = (params = {}) => {
        return new Promise(async (resolve) => {
            try {
                const {data: kotData, total} = await getOldKotByOrderIdFxn({
                    ...params,
                    results: 1000,
                    count: 1000,
                    orderId: data.orderId,
                });
                if (kotData.total) {
                    setOldKots({
                        data: kotData.data,
                        total: kotData.total,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const editOrder = () => {
        return new Promise(async () => {
            try {
                orderData.orderId = orderId
                orderData.updatedBy = userType
                orderData.updatedByUserId = userId
                orderData.setteledAmount = orderData.netAmount
                const editOrderData = await editOrderFxn({
                    ...orderData
                });
                if (editOrderData.success) {
                    notification.success({
                        message: editOrderData.message
                    })
                    setOrderData(initialEditData)
                    setIsEditOrderModalOpen(false)
                    tableRef.current.reload()
                } else {
                    notification.error({
                        message: editOrderData.message
                    })
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    useEffect(() => {
        getOldKotData();
    }, [data.orderId]);

    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (dates.fromDate && dates.toDate) {
                    params.date = {
                        $gte: moment(dates.fromDate).startOf('day')._d,
                        $lte: moment(dates.toDate).endOf('day')._d,
                    };
                }
                if (choosePaymentType) {
                    params.paymentType = choosePaymentType
                }
                params.status = {$in: ["Paid"]};
                // params.status = {$in: ["Done", "Paid"]};
                params.guest = false;
                // params.tableNo = {$not: {$regex: "g"}};
                if (tableFilterData.length > 0 ){
                    params.tableNo = tableFilterData;
                }
                if (userName){
                    params.userId = userName;
                }
                const data = await fetchAllOrder({...params});
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const refreshTable = () => {
        loadPaymentData()
        tableRef.current.reload();
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (item) => {
                return (
                    <>
                        {item == "Pending" ? (
                            <label className={"label label-warning label-style"}>
                                Pending
                            </label>
                        ) : (
                            ""
                        )}
                        {item == "Paid" ? (
                            <label className={"label label-success label-style"}>Paid</label>
                        ) : (
                            ""
                        )}
                        {item == "Done" ? (
                            <label className={"label label-primary label-style"}>Done</label>
                        ) : (
                            ""
                        )}
                    </>
                );
            },
        },
        {
            title: "Table No.",
            dataIndex: "tableNo",
            key: "tableNo",
        },
        {
            title: "Order No.",
            dataIndex: "orderNo",
            key: "orderNo",
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
        },
        {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
        },
        {
            title: "CGST Amount",
            dataIndex: "cgstAmount",
            key: "cgstAmount",
        },
        {
            title: "SGST Amount",
            dataIndex: "sgstAmount",
            key: "sgstAmount",
        },
        {
            title: "Service Charges",
            dataIndex: "serviceTaxAmount",
            key: "serviceTaxAmount",
        },
        {
            title: "Net Amount",
            dataIndex: "netAmount",
            key: "netAmount",
        },
        {
            title: "Cash Discount",
            dataIndex: "cashDiscount",
            key: "cashDiscount",
        },
        {
            title: "Final Amount",
            dataIndex: "finalAmount",
            key: "finalAmount",
        },
        {
            title: "customer Paid",
            dataIndex: "customerPaid",
            key: "customerPaid",
        },
        {
            title: "Cash Amount",
            dataIndex: "cashAmount",
            key: "cashAmount",
        },
        {
            title: "Upi Amount",
            dataIndex: "upiAmount",
            key: "upiAmount",
        },
        {
            title: "Card Amount",
            dataIndex: "cardAmount",
            key: "cardAmount",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (text, record) => (
                <span>{moment(record.date).format("DD/MM/YYYY")} </span>
            ),
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            render: (text, record) => (
                <span>{moment(record.date).format("hh:mm A")} </span>
            ),
        },

        {
            title: "Added By User",
            dataIndex: "userName",
            key: "userName",
            render: (item, record) => (
                item ? (item) : null
            ),
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, record) => {
                return (
                    <>
                        {userType != 'cashier' && record.status && record.status !== "Pending" ? (
                            <>
                                <a
                                    className="empty_btn"
                                    onClick={() => {
                                        setIsModalOpen(true);
                                        if (record) {
                                            console.log(record,"orderdata")
                                            setData({
                                                orderNo: record.orderNo,
                                                totalAmount: record.totalAmount,
                                                cgstAmount: record.cgstAmount,
                                                sgstAmount: record.sgstAmount,
                                                serviceTaxAmount: record.serviceTaxAmount,
                                                netAmount: record.netAmount,
                                                orderId: record._id,
                                                discountPer: record.discountPer,
                                            });
                                            if (record.cashAmount && record.cashAmount != 0 && !record.upiAmount && !record.cardAmount){
                                                setViewEdit(true)
                                            }
                                        }
                                    }}
                                >
                                    <i className={"fa fa-eye"}/>
                                </a>

                                <a
                                    className="empty_btn"
                                    onClick={() => {
                                        loadOrderData(record._id);
                                    }}
                                >
                                    <i className={"fa fa-print"}/>
                                </a>
                                <a
                                    className="empty_btn"
                                    onClick={() => {
                                        setIsEditOrderModalOpen(true)
                                        oldOrderData(record._id);
                                        setOrderId(record._id)
                                    }}
                                >
                                    <i className={"fa fa-edit"}/>
                                </a>
                            </>
                        ) : (
                            ""
                        )}

                    </>
                );
            },
        },
    ];

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.hide) {
                columnArr.push(item);
            }
        });
        return columnArr;
    };

    let orderN = (
        <div>
            Order Number : {data.orderNo} | <small style={{fontSize: 13}}> (No of Kots - {oldKots.total}) </small>
        </div>
    );

    let fixedAmount = (amount) => {
        return amount ? `Rs. ${amount.toFixed(2)}` : 0
    }

    let loadTables = async () => {
        let {data} = await tableListFxn();
        let filteredData = data.filter(item =>
            item.name.toLowerCase() !== 'hod' &&
            item.name.toLowerCase() !== 'md' &&
            item.name.toLowerCase() !== 'guest'
        );
        setTableList(filteredData);
    }

    let loadUsers = async (params = {}) => {
        params.results = 200
        const {data} = await userListFxn({...params});
        setUsers(data);
    }

    return (
        <PageHeader title={"Order List"}>
            {/*{totalNetAmount}*/}

            <div className="row" key={paymentData ? JSON.stringify(paymentData) : "666"}>
                {paymentData && paymentData.length ? paymentData.map((item) => {
                    return (
                        <div className="col-lg-3 col-sm-6 col-12" key={item.name}>
                            <div className="dash-widget">
                                <div className="dash-widgetimg">
                                        <span>
                                          <img src="assets/img/icons/dash1.svg" alt="img"/>
                                        </span>
                                </div>
                                <div className="dash-widgetcontent" key={item.value}>
                                    <h5>
                                          <span className="counters" data-count={item.value}>
                                            {/*Rs. {item.value ? item.value.toFixed(2) : 0}*/}
                                            Rs. {item.finalAmount ? item.finalAmount.toFixed(2) : 0}
                                          </span>
                                    </h5>

                                    {/*<h5 className={'text-primary'}>{item.name}</h5>*/}
                                    <h5 className={'text-primary'}>Total Amount</h5>
                                    <div style={{fontSize: 12, marginTop: 10, color: "#555", fontWeight: "bold"}}>
                                        CGST : {fixedAmount(item.cgstAmount)}<br/>
                                        SGST : {fixedAmount(item.sgstAmount)}<br/>
                                        Service Charges : {fixedAmount(item.serviceTaxAmount)}<br/>
                                        Tip : {fixedAmount(item.tip)}<br/>
                                        Cash Dis. : {fixedAmount(item.cashDiscount)}<br/>
                                        Cash Amount : {fixedAmount(item.cashAmount)}<br/>
                                        Card Amount. : {fixedAmount(item.cardAmount)}<br/>
                                        Upi Amount. : {fixedAmount(item.upiAmount)}<br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
            <div className="row mb-3">

                <div className="col-md-2">
                    <InputBox title={'From Date'}>
                        <DatePicker
                            value={dates.fromDate}
                            showClear={true}
                            placeholder={'Choose from Date'}
                            onChange={(value) => {
                                setDates({...dates, fromDate: value})
                            }}
                        />
                    </InputBox>
                </div>
                <div className="col-md-2">
                    <InputBox title={'To Date'}>
                        <DatePicker
                            value={dates.toDate}
                            showClear={true}
                            placeholder={'Choose to Date'}
                            onChange={(value) => {
                                setDates({...dates, toDate: value})
                            }}
                        />
                    </InputBox>
                </div>
                <div className="col-md-2">
                    <InputBox title={'Payment Type'}>
                        <select className="form-control search-filters"
                                onChange={(e) => {
                                    setChoosePaymentType(e.target.value)
                                }}
                                value={choosePaymentType}
                        >
                            <option value={""}>Choose Payment Type</option>
                            {PaymentType.map((item) => {
                                return (
                                    <option value={item.key}>
                                        {item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </InputBox>
                </div>
                <div className="col-md-2">
                    <InputBox title={'Choose Venue'}>
                        <select className="form-control search-filters"
                                onChange={(e) => {
                                    setTableName(e.target.value)
                                }}
                                value={tableName}
                        >
                            <option value={""}>Choose Venue</option>
                            {tableList.map((item) => {
                                return (
                                    <option value={item.name}>
                                        {item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </InputBox>
                </div>
                <div className="col-md-2">
                    <InputBox title={'Choose User'}>
                        <select className="form-control search-filters"
                                onChange={(e) => {
                                    setUserName(e.target.value)
                                }}
                                value={userName}
                        >
                            <option value={""}>Choose User</option>
                            {userList.map((item) => {
                                return (
                                    <option value={item._id}>
                                        {item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </InputBox>
                </div>
                <div className="col-md-1 mt-4">
                    <div className="search-wrap">
                        <button className="btn btn-info" onClick={refreshTable}>
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className="card mb-0">
                <div className="table-responsive" style={{"overflow-y": 'hidden'}}>
                    <Table
                        apiRequest={apiRequest}
                        columns={getColumns()}
                        pagination={DefaultTablePagination()}
                        ref={tableRef}
                    />
                </div>
            </div>

            {isModalOpen && (
                <Modal
                    visible={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setViewEdit(false)
                    }}
                    title={orderN}
                >
                    <table key={refreshView} className="table table-bordered">
                        <thead>
                        <tr>
                            <td>Total Amount</td>
                            <td>Cgst Amount</td>
                            <td>Sgst Amount</td>
                            <td>Service Charges</td>
                            <td>Net Amount</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{data.totalAmount}</td>
                            <td>{data.cgstAmount}</td>
                            <td>{data.sgstAmount}</td>
                            <td>{data.serviceTaxAmount}</td>
                            <td>{data.netAmount}</td>
                        </tr>
                        </tbody>
                    </table>

                    <div className={"mt-2"}>
                        {oldKots && oldKots && oldKots.data.length
                            ? oldKots.data.map((item, index) => {
                                return (
                                    <div key={item.kotNo}>
                                        <div className="dark-background d-flex justify-content-between">
                                            <h7>
                                                Kot - {item.kotNo} Time -{" "}
                                                {moment(item.time).format("hh:mm A")}
                                            </h7>
                                            <div>
                                                {userType == "admin" && viewEditKot ? (
                                                    <i
                                                        className="fa fa-edit far"
                                                        onClick={() => {
                                                            setEditKotModal(true);
                                                            setKotId(item._id);
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        {item.products.map((product) => {
                                            return (
                                                <>
                                                    <ul
                                                        className="product-lists mb-0 p-1"
                                                        key={product._id}
                                                    >
                                                        <li className="w-50">
                                                            <div className="productimg">
                                                                <div className="productcontet">
                                                                    <h4 className={"mb-0"}>
                                                                        {product &&
                                                                        product.productId &&
                                                                        product.productId.vegNonVeg ? (
                                                                            <>
                                                                                {getVegStatus(
                                                                                    product.productId.vegNonVeg
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {product.name}{" "}
                                                                        {product.variant &&
                                                                        ` (${product.variant})`}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="increment-decrement">
                                                                <div className="input-groups">
                                                                    <h7>Qty:</h7>
                                                                    <input
                                                                        type="text"
                                                                        name="child"
                                                                        value={product.quantity}
                                                                        className="quantity-field"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div style={{fontSize: "80%"}}>
                                                                Rs. {product.price}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <strong>Rs. {product.totalAmount}</strong>
                                                        </li>
                                                    </ul>
                                                </>
                                            );
                                        })}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </Modal>
            )}
            {isEditOrderModalOpen && (
                <Modal
                    visible={isEditOrderModalOpen}
                    onClose={() => {
                        setIsEditOrderModalOpen(false);
                    }}
                    title={`Edit Order ( Order No. - ${orderNumber} )`}
                >
                    <div className={'d-flex flex-wrap'}>
                        {[
                            { title: "Total Amount", field: "totalAmount" },
                            { title: "Discount Amount", field: "discount" },
                            { title: "CGST Amount", field: "cgstAmount" },
                            { title: "SGST Amount", field: "sgstAmount" },
                            { title: "Service Charges", field: "serviceTaxAmount" },
                            { title: "Net Amount", field: "netAmount" },
                            { title: "Cash Discount", field: "cashDiscount" },
                            { title: "Final Amount", field: "finalAmount" },
                            { title: "Customer Paid", field: "customerPaid" },
                            { title: "Cash Amount", field: "cashAmount" },
                            { title: "UPI Amount", field: "upiAmount" },
                            { title: "Card Amount", field: "cardAmount" }
                        ].map((input) => (
                            <div className={'d-flex col-md-6'} key={input.field}>
                                <InputBox title={input.title}>
                                    <input
                                        style={{ width: '350px' }}
                                        value={orderData[input.field]}
                                        className={"form-control"}
                                        type={"number"}
                                        onChange={(e) => {
                                            setOrderData({
                                                ...orderData,
                                                [input.field]: e.target.value,
                                            });
                                        }}
                                    />
                                </InputBox>
                            </div>
                        ))}
                    </div>
                    <div className="text-center mt-3">
                        <button className="btn btn-info" onClick={editOrder}>
                            Submit
                        </button>
                    </div>
                </Modal>

            )}

            {editKotModal && (
                <Modal
                    visible={editKotModal}
                    onClose={() => {
                        setEditKotModal(false);
                    }}
                    title={"Edit Kot"}
                >
                    <div className={"mt-2"}>
                        {editKotProducts &&
                        editKotProducts.map((product) => {
                            return (
                                <>
                                    <ul
                                        className="product-lists mb-0 p-1"
                                        key={product._id}
                                    >
                                        <li className="w-50">
                                            <div className="productimg">
                                                <div className="productcontet">
                                                    <h6 className={"mb-0"}>
                                                        {product &&
                                                        product.productId &&
                                                        product.productId
                                                            .vegNonVeg ? (
                                                            <>
                                                                {getVegStatus(
                                                                    product.productId
                                                                        .vegNonVeg
                                                                )}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {product.name}{" "}
                                                        {product.variant &&
                                                        ` (${product.variant})`}
                                                    </h6>
                                                </div>
                                            </div>
                                        </li>

                                        <li>
                                            <div className="increment-decrement">
                                                <div className="input-groups">
                                                    <input
                                                        type="button"
                                                        value="-"
                                                        onClick={() => {
                                                            events.desEditcQty(
                                                                product
                                                            );
                                                        }}
                                                        className="button-minus dec button"
                                                    />
                                                    <input
                                                        type="text"
                                                        name="child"
                                                        value={product.quantity}
                                                        className="quantity-field"
                                                    />
                                                    <input
                                                        type="button"
                                                        value="+"
                                                        onClick={() => {
                                                            events.incEditQty(
                                                                product
                                                            );
                                                        }}
                                                        className="button-plus inc button "
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                              <span
                                                  style={{
                                                      fontSize: "80%",
                                                  }}
                                              >
                                                Rs. {product.price}
                                              </span>
                                        </li>
                                        <li>
                                            <strong>
                                                Rs. {product.totalAmount}
                                            </strong>
                                        </li>
                                    </ul>
                                </>
                            );
                        })}

                        <div className="d-flex justify-content-end mt-2">
                            <button
                                onClick={() => {
                                    handleEditkot(editKotProducts, "kot");
                                }}
                                className="btn btn-success"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </Modal>
            )}

            <div style={{display: "none"}}>
                {singleOrderData && singleOrderData._id ? (
                    <PrintDesign ref={componentRef} singleOrderData={singleOrderData}/>
                ) : null}
            </div>
        </PageHeader>
    );
}

export default OrderList;
