import React, {useEffect, useState} from "react";
import {
    Form,
    HyperLink,
    notification,
} from "../components/Elements/appUtils";

import PageHeader from "../components/Elements/pageHeader";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../components/_utils/formUtils";
import {useNavigate} from "react-router-dom";

import { singleNoteFxn, updateNoteFxn } from "./pos/actions";

const EditSpecialNote = (props) => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [noteId, setNoteId] = useState("");
    useEffect(() => {
        checkNoteId();
    }, []);

    let id;

    const checkNoteId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        id = searchParams.get("_id");
        if (id) {
            getSingleNote(id);
            setNoteId(id);
        }
    };
    const getSingleNote = async (id) => {
        let {data, success} = await dispatch(singleNoteFxn(id));
        if (success) {
            setTimeout(() => {
                props.form.setFieldsValue({
                    specialNote: data.specialNote,
                    
                });

            }, 300);


        }
    };

    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {

                valData._id = noteId

                let x = await dispatch(updateNoteFxn(valData));
                if (x && x.success) {
                    navigate('/specialNote')
                }
            } else {
                if (err.specialNote) {
                    notification.warning({
                        message: `Please enter Special Note`,
                    });
                    return;
                }


                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };

    let inputTypes = {

        fields: [
            {
                key: "specialNote",
                label: "Special Note",
                span: "col-md-4",
                placeholder: "Special Note",
                type: "text",

            },
           

        ]

    };


    return (
        <>
            <PageHeader title={"Edit Special Note"}>
                <div className="card">
                    <div className="card-body">
                        <Form onSubmit={handleSubmit}>
                            <div className="form-elements-wrapper">
                                <div className={"row"}>
                                    {inputTypes.fields.map((item, key) => {
                                        return !item.hidden ? (
                                            <div
                                                className={`${item.span ? item.span : "col-md-6"}`}
                                                key={key}
                                            >
                                                <GetEachFormFields {...props.form} item={item}/>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            </div>

                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                           
                        </Form>
                    </div>
                </div>
            </PageHeader>
        </>
    );
};

export default Form.create()(EditSpecialNote);
