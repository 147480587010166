import {appAxios as axios, getToken} from "../../../request";
import {
    editKotOfDoneOrderURL,
    editOrderUrl,
    getAllOrdersUrl,
    pendingKotUrl,
    saveOrderOfDoneOrderUrl,
    updateKotStatusUrl
} from "../apis";
import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";

export const getPendingKotFxn = async () => {
    let {data} = await axios.post(pendingKotUrl(), {}, getToken());
    return data;
}

export const updateKotStatusFxn = async (valData) => {
    let {data} = await axios.post(updateKotStatusUrl(), valData, getToken());
    return data;
}

export const fetchAllOrder = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getAllOrdersUrl(), config);
    return data;
};

export const editOrderFxn = async (valData) => {
    let {data} = await axios.post(editOrderUrl(), valData, getToken());
    return data;
}

export const editKotOfSaveOrderFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(editKotOfDoneOrderURL(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const saveOrderFromDoneOrderFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(saveOrderOfDoneOrderUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
