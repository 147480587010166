import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {appAxios as axios, getToken} from "../../../request";
import {
    addKotUrl,
    addOrderUrl, editKotUrl, getCouponDisUrl, getOldKotByOrderIdUrl,
    getOldKotUrl, getPrintProductsUrl,
    groupedProductUrl,
    OrderPaymentUrl,
    saveOrderUrl,
    tableListUrl,
    transferTableUrl
} from "../apis";
import {getSpecialNotesUrl, singleNoteUrl, updateNoteUrl} from "../apis";

export const tableListFxn = async () => {
    let config = {
        params: {},
        ...await getToken()
    }
    let {data} = await axios.get(tableListUrl(), config)
    return data;
}


export const getOldKotFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(getOldKotUrl(), config)
    return data;
}

export const getOldKotByOrderIdFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    let {data} = await axios.get(getOldKotByOrderIdUrl(), config)
    return data;
}

export const groupedProductFxn = async () => {
    let config = {
        params: {},
        ...await getToken()
    }
    let {data} = await axios.get(groupedProductUrl(), config)
    return data;
}

export const addOrderFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addOrderUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const saveOrderFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(saveOrderUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const TransferTableFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    console.log(valData,"val");
    const {data} = await axios.post(transferTableUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};


export const OrderPaymentFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(OrderPaymentUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
export const getPrintProductsFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getPrintProductsUrl(), config);
    dispatch(hideLoader())
    return data;
};

export const editKotFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(editKotUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const getCouponDisFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(getCouponDisUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
export const FetchSpecialNotes = async (valData) => {
    // let config = { params: valData };
    const {data} = await axios.get(getSpecialNotesUrl(), getToken());
    return data;
};
export const singleNoteFxn = (id) => async (dispatch) => {
    dispatch(showLoader());

    let {data} = await axios.get(singleNoteUrl(id), getToken());
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    }

    return data;
};

export const updateNoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateNoteUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
};
