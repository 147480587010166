import {hideLoader, showLoader} from "../../../actions/loader";
import notification from "../../../components/Elements/Notification";
import {appAxios as axios, getToken} from "../../../request";
import {
  addCouponUrl,
  deleteCouponUrl,
  editCouponUrl,
  getCouponDataUrl,
  getCouponUrl,
} from "../api";

export const addCouponFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(addCouponUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const editCouponFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(editCouponUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};
export const deleteCouponFxn = (valData) => async (dispatch) => {
  dispatch(showLoader());
  let {data} = await axios.post(deleteCouponUrl(), valData, getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  } else {
    notification.success({
      message: data.message || "Success",
    });
  }
  return data;
};

export const getSingleCouponFxn = (id) => async (dispatch) => {
  dispatch(showLoader());

  let {data} = await axios.get(getCouponUrl(id), getToken());
  dispatch(hideLoader());
  if (data.error) {
    notification.error({
      message: data.message || "Error",
    });
  }

  return data;
};
export const fetchCoupon = async (valData) => {
  let config = {
    params: {...valData},
    ...await getToken()
  };
  const {data} = await axios.get(getCouponDataUrl(), config);
  return data;
};
