import React, { useEffect, useState } from "react";
import { InputBox } from "../components/Elements/appUtils";
import _ from "lodash";
import Select from "rc-select";
import { fetchPosCustomer } from "../containers/customer/action";
import "rc-select/assets/index.less";

const { Option } = Select;

const ChooseCustomerPos = (props) => {
  let { callback, customerId, type } = props;
  let [clientId, setClientId] = useState(undefined);
  let [arrData, setArrData] = useState([]);

  const events = {
    loadDefaultClient: async () => {
      if (customerId) {
        let params = {
          results: 20,
          count: 20,
          _id: customerId,
          regExFilters: ["name", "mobileNo", "email"],
          select: ["name", "mobileNo"],
        };
        let { data } = await fetchPosCustomer(params);

        setArrData(data.data);
      } else {
        setArrData([]);
      }
    },
    fetchClient: async (searchValue) => {
      if (searchValue) {
        let params = {
          results: 20,
          count: 20,
          type: type,
          regExFilters: ["name", "mobileNo", "email"],
          select: ["name", "mobileNo"],
        };

        if (!isNaN(searchValue)) {
          params.mobileNo = searchValue;
        } else {
          params.name = searchValue;
        }
        let { data } = await fetchPosCustomer(params);
        setArrData(data.data);
      } else {
        setArrData([]);
      }
    },

    handleChange: (clientId) => {
      let item = _.find(arrData, (value) => {
        return value._id == clientId;
      });

      setClientId(clientId);
      callback(item);
    },
  };
  useEffect(() => {
    events.loadDefaultClient();
  }, [customerId]);

  return (
    <>
      <InputBox title={"Choose Customer"}>
        <div className={"rowFlex"}>
          <Select
            showSearch={true}
            onSearch={events.fetchClient}
            filterOption={false}
            autoClearSearchValue={true}
            allowClear={true}
            value={clientId}
            showArrow={true}
            onChange={events.handleChange}
            placeholder="Choose Customer"
            className={`rc-select rc-select1`}
            choiceTransitionName="rc-select-selection__choice-zoom"
            style={{ width: "100%" }}
            dropdownRender={(menu) => {
              return <div key={arrData}>{menu}</div>;
            }}
          >
            {arrData && arrData.length
              ? arrData.map((item, index) => {
                  return (
                    <Option key={item._id} value={item._id}>
                      {`${item.name} (${item.mobileNo})`}
                    </Option>
                  );
                })
              : null}
          </Select>
        </div>
      </InputBox>
    </>
  );
};
export default ChooseCustomerPos;
