import React, {useEffect, useRef, useState} from "react";
import {Table, Tooltip, Popconfirm, DefaultTablePagination, notification} from "../../components/Elements/appUtils";
import {userListFxn} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

let filterInit = {
    name: "",
    menu: "",
    categoryId: ""
}

function UserList() {
    let dispatch = useDispatch()
    let tableRef = useRef();
    const navigate = useNavigate();
    let [filter, setFilter] = useState(filterInit)
    const [categoryList, setCategoryList] = useState([])
    const [k, setK] = useState();
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await userListFxn({...params, regExFilters: ["name"], userType: "captain"});
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };


    const search = async () => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    };


    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },

        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },

        {
            title: "Address",
            dataIndex: "address",
            key: "address"
        },
        {
            title: "Tables",
            dataIndex: "tables",
            key: "tables",
            render: (item) => {
                return item && item.length ? item.join(', ') : null
            }
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, item) => {
                return (
                    <>
                        <a className={"empty_btn"}
                           onClick={() => {
                               navigate(`/editUser?_id=${item._id}`);
                           }}>
                            <i className={"fa fa-edit far"}/>
                        </a>
                    </>
                );
            },
        },

    ];


    return (
        <PageHeader
            title={"User"}
            extraLink={[
                {
                    name: "Add User",
                    link: "/add-user",
                },
            ]}
        >


            <div className="card mb-0">
                <div className="table-responsive">
                    <Table
                        apiRequest={apiRequest}
                        columns={columns}
                        pagination={DefaultTablePagination()}

                        ref={tableRef}
                    />
                </div>
            </div>
        </PageHeader>
    );
}

export default UserList;
