import React, {useEffect, useState} from "react";
import {dashboardCountFxn, paymentGroupByPaymentMethodFxn} from "./action";
import _ from "lodash"
import Dashboard from "./index";
import KotView from "../kotView";
import Tables from "../pos/views/posTables";
import CouponComponent from "../coupon/addCoupon";
import {adminTypes, kitchenTypes} from "../../components/Elements/appUtils";
import {connect} from "react-redux";

let initPaymentMode = [
    {name: "Card", value: 0},
    {name: "Cash", value: 0},
    {name: "UPI", value: 0}
]
let initState = {
    totalOrder: 0,
    totalCustomer: 0,
    totalKot: 0,
    totalProduct: 0,
}
const DashboardComponent = (props) => {
    let {currentUser} = props
    const [paymentData, setPaymentData] = useState(initPaymentMode)
    const [state, setState] = useState(initState)
    const apiRequest = async (params) => {
        const {data} = await dashboardCountFxn({...params});
        setState(data);
    };


    const loadPaymentData = async () => {
        const {data} = await paymentGroupByPaymentMethodFxn({});
        let cloneDoc = _.clone(initPaymentMode);
        _.each(cloneDoc, (item) => {
            let findVal = _.find(data, (doc) => {
                return doc._id == item.name.toLowerCase()
            })
            if (findVal) {
                item.value = findVal.totalAmount
            }
        })
        setPaymentData(cloneDoc)
    };

    useEffect(() => {
        apiRequest()
        loadPaymentData()
    }, [])

    return (
        <>
            {adminTypes && adminTypes.includes(currentUser.userType) ? <>
                <Dashboard/>
            </> : null}
            {kitchenTypes && kitchenTypes.includes(currentUser.userType) ? <>
                <KotView/>
            </> : null}
            {currentUser.userType && currentUser.userType === "captain" ?
                <>
                    <Tables/>
                </> : null}
            {currentUser.userType && currentUser.userType === "couponManager" ?
                <>
                    <CouponComponent/>
                </> : null}

        </>
    );
};

const mapStateToProps = ({counter, global}) => ({
    currentUser: global.currentUser
})
const DashboardWrapperComponent = connect(
    mapStateToProps,
    null
)(DashboardComponent)

export default DashboardWrapperComponent;
