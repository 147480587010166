import {appAxios as axios, getToken} from "../../../request";
import {getAllKotUrl, pendingKotUrl, updateKotStatusUrl, updatePriorityUrl, updateStatusKotUrl} from "../apis";

export const getPendingKotFxn = async () => {
    let {data} = await axios.post(pendingKotUrl(), {}, getToken());
    return data;
}

export const updateKotStatusFxn = async (valData) => {
    let {data} = await axios.post(updateKotStatusUrl(), valData, getToken());
    return data;
}

export const updatePriorityFxn = async (valData) => {
    let {data} = await axios.post(updatePriorityUrl(), valData, getToken());
    return data;
}

export const fetchAllKot = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getAllKotUrl(), config);
    return data;
};

export const updateStatusKotFxn = async (valData) => {
    let {data} = await axios.post(updateStatusKotUrl(), valData, getToken());
    return data;
}

