import React, { useEffect, useRef, useState } from "react";
import { Modal, Table, Tooltip } from "../../components/Elements/appUtils";
import { fetchSale } from "./action";
import PageHeader from "../../components/Elements/pageHeader";
import moment from "moment";
import { getToken } from "../../request";
import Image from "../../printer-50.png"
// import { getProductReport } from "../purchase/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../actions/loader";
import { getSingleSaleUrl } from "./api";
const ProductReportList = (props) => {
  let {customerDetails,Report, visible, onClose}=props
  console.log(customerDetails, "customerDetails")
  return (
    <>
      <Modal
          title={"Product List"}
          width={"80%"}
          visible={visible}
          onClose={onClose}
        >
      <div className={"mb50 bordered"}>
            {customerDetails? (
              <div className="d-inline-block border border-2">
                <div className="item-flex">
                  <h5 className="m-3 d-block">
                    Name:{" "}
                    <span className="font-weight-normal ms-2">
                      {customerDetails.customerName}
                    </span>
                  </h5>
                  <h5 className="m-3 d-block">
                    State:{" "}
                    <span className="font-weight-normal ms-2">
                      {customerDetails.customerState}
                    </span>
                  </h5>
                  <h5 className="m-3 d-block">
                    Invoice No:{" "}
                    <span className="font-weight-normal ms-2">
                      {customerDetails.customerInvoice}
                    </span>
                  </h5>
                 
                  <h5 className="m-3 d-block">
                    Date:{" "}
                    <span className="font-weight-normal ms-2">
                      {moment(customerDetails.purchaseDate).format(
                        "DD-MMM-YYYY"
                      )}
                    </span>
                  </h5>
                </div>
              </div>
            ) : null}
            {Report && Report?.length ? (
              <table className="table table-bordered mt-4" style={{border: "1px solid black"}}>
                <thead>
                  <tr>
                    <td>Sr no.</td>
                    <td>Product Name</td>
                    <td>Report Type</td>
                  
                    <td>Qty.</td>
                    <td>Rate</td>
                    <td>Dis Amt.</td>
                    <td>Taxable Amt.</td>
                    {Report.some(
                      (item) => item.cgstAmount !== 0 && item.sgstAmount !== 0
                    ) ? (
                      <>
                        <td>CGST Amt.</td>
                        <td>SGST Amt.</td>
                      </>
                    ) : (
                      <td>IGST Amt.</td>
                    )}
                    <td>Net Amt.</td>
                  </tr>
                </thead>
                <tbody>
                  {Report &&
                    Report.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.productName}</td>
                        <td>{item.reportType}</td>
                        
                        <td>{item.quantity}</td>
                        <td>Rs. {item.price}</td>
                        <td>
                          Rs. {item.discountAmount} ({item.discount}%)
                        </td>
                        <td>Rs. {item.taxableAmount}</td>
                        {item.cgstAmount === 0 && item.sgstAmount === 0 ? (
                          <>
                            <td>
                              Rs. {item.igstAmount} ({item.gst}%)
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              Rs. {item.cgstAmount} ({item.gst / 2}%)
                            </td>
                            <td>
                              Rs. {item.sgstAmount} ({item.gst / 2}%)
                            </td>
                          </>
                        )}

                        <td>Rs. {item.netAmount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : null}
          </div>
          <div className="Total-section mt-4">
            <div className="row">
              <div className="col-sm-7 mt-1">
                {/* <h6 style={{ fontSize: "13px" }}>Payment Details:</h6>
                <div
                  style={{
                    borderBottom: "1px solid #000",
                    marginTop: "7px",
                    width: "300px",
                  }}
                ></div>

                <div
                  style={{
                    borderBottom: "1px solid #000",
                    marginTop: "10px",
                    width: "300px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "13px" }}>PAYMODE</span>
                  <span style={{ fontSize: "13px" }}>AMOUNT</span>
                </div> */}
              </div>
              <div className="col-sm-5" style={{ border: "1px solid black" }}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-5">
                        <div style={{ textAlign: "right" }}>
                          <p style={{ fontSize: "13px" }}>
                            <b>Taxable Amt :</b>
                          </p>
                          {customerDetails.cgstAmount !== 0 &&
                        customerDetails.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              <b>CGST Total :</b>{" "}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <b>SGST Total :</b>{" "}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            <b>IGST Total : </b>
                          </p>
                        )}
                          <p style={{ fontSize: "13px" }}>
                            <b>Total Amt :</b>
                          </p>
                          <div
                            style={{
                              borderBottom: "1px solid #000",
                              marginTop: "7px",
                              width: "255%",
                              marginLeft: "8px",
                              textAlign: "right",
                            }}
                          ></div>
                          <p style={{ fontSize: "13px" }}>
                            <b>Paid Amt :</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <div style={{ textAlign: "right" }}>
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            ₹ {parseFloat(customerDetails.taxableAmount).toFixed(2)}
                          </p>
                          {customerDetails.cgstAmount !== 0 &&
                        customerDetails.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹{parseFloat(customerDetails.cgstAmount).toFixed(2)}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹{parseFloat(customerDetails.sgstAmount).toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            ₹ {parseFloat(customerDetails.igstAmount).toFixed(2)}
                            
                          </p>
                        )}
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(customerDetails.gstAmount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" , marginTop: "8px"}}>
                          ₹ {customerDetails.netAmount}
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
         
          </div>
        
          </Modal>
    </>
  );
};

function Sale() {
  let tableRef = useRef();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [salesId, setSalesId] = useState(null);
  const [Report, setReport] = useState([]);
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    customerState: "",
    customerInvoice: "",
    customerNumber: "",
    purchaseDate: "",

  });

  console.log(customerDetails, "salesId")

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      try {
        params = { results: 10000 };
        const data = await fetchSale({ ...params });
        resolve(data);
        setCustomerData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  console.log(customerData, "customerData")

  const fetchcustomerdata = () => {
    const allrecordofcustomer = customerData.filter(
      (item) => item._id == salesId
    );

    allrecordofcustomer.map((i) => {
      console.log(i, "cafpjpj");
      setCustomerDetails({
        customerName: i.customerId.name,
        customerState: i.customerId.billingState,
        customerNumber: i.customerId.mobileNo,
        customerInvoice: i.invoiceNumber,
        purchaseDate: i.purchaseDate,
        netAmount: i.netAmount,
        gstAmount: i.gstAmount,
        paidAmount: i.paidAmount,
        taxableAmount: i.taxableAmount,
        cgstAmount: i.cgstAmount,
        sgstAmount: i.sgstAmount,
        igstAmount: i.igstAmount

      });
      return;
    });
  };

  const fetchProductReport = async () => {
    try {
      let config = {
      
        ...(await getToken()),
      };
    
      const response = await axios.get(getSingleSaleUrl(salesId), config);
      setReport(response.data.data);
      console.log(response.data.data, "response")
    
    } catch (error) {
      console.error("Error getting the data:", error);
    }
  };

  useEffect(() => {
    apiRequest();
  }, []);

  useEffect(() => {
     fetchProductReport();
    fetchcustomerdata();
  }, [salesId]);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "customerId",
      key: "customerId",
      render: (text) => <span>{text?.name}</span>,
    },
  
    {
      title: "sales Date",
      dataIndex: "salesDate",
      key: "salesDate",
      render: (text) => <span>{moment(text).format("DD/MMM/YY")}</span>,
    },
   
    {
      title: "Total Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Recieved Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <span>Rs. {text}</span>,
    },
    {
      title: "Actions",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <>
          <Tooltip title={"View"}>
            <a
              className={"empty_btn"}
              onClick={() => {
                setIsModalOpen(true);
                setSalesId(record._id);
              }}
            >
              <i className={"fa fa-eye far"}></i>
            </a>
          </Tooltip>
          <Tooltip title={"Print"}>
            <a
              href={`/print-sale?_id=${record._id}`}
              target="_blank"
              className="m-2"
            >
              <img src={Image} className="print" width={"20px"} height={"30px"} />
            </a>
          </Tooltip>
          
        </>
      ),
    },
  ];

  return (
    <PageHeader
      title={"Sales"}
      extraLink={[
        {
          name: "Add Sale",
          link: "/addSale",
        },
      ]}
    >
      <div className="card">
        <div className="card-body">
          <Table apiRequest={apiRequest} columns={columns} ref={tableRef} />
        </div>
      </div>
      {isModalOpen ? (
          <ProductReportList visible={isModalOpen} Report={Report} onClose={()=>{
            setIsModalOpen(false)
            setReport([])
            setSalesId("")
          }} customerDetails={customerDetails}/>
        
      ):null}
    </PageHeader>
  );
}

export default Sale;
