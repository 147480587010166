import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    Form,
    notification,
    HyperLink,
    fixed2Digit,
    InputBox,
    Card,
    Modal,
    Button,
} from "../../components/Elements/appUtils";
import AddCustomerComponent from "../../components/addCustomerComponent";
import {useDispatch} from "react-redux";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import { getItemLocalstorage } from "../../components/Elements/appUtils";
import {
    States,
    PaymentTypes,
    IndianStates,
} from "../../components/_utils/appUtils";

import {addSaleFxn} from "./action";
import { fetchCustomer } from "../customer/action";
import ItemListComponent from "./itemlistComponent";
import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";

import {useNavigate} from "react-router-dom";

import moment from "moment";
import ChooseCustomer from "../../components/chooseCustomer";


function AddSale(props) {
    const [formData, setFormData] = useState([
        {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
    ]);
    const [showDeleteButtons, setShowDeleteButtons] = useState([false]);
    const [isModal2Open, setIsModal2Open] = useState(false);

    const handleInputChange = (index, field, value) => {
        const newData = [...formData];
        newData[index][field] = value;
        setFormData(newData);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.map((btn, idx) => (idx === index ? true : btn))
        );
    };
    const companyStateCode = getItemLocalstorage("user")["stateCode"];
    const handleDelete = (index) => {
        const newPayments = [...formData];
        newPayments.splice(index, 1);
        setFormData(newPayments);
        setShowDeleteButtons((prevButtons) =>
            prevButtons?.filter((_, idx) => idx !== index)
        );
    };

    const handleAddField = () => {
        const sumAmount = formData.reduce(
            (sum, item) => sum + parseFloat(item.amount) || 0,
        );
        if (sumAmount > getFieldValue("paidAmount")) {
            notification.warning({
                message: "Amount Cannot Be Greater Than Receiving Amount",
            });
            return;
        }
        if (sumAmount == getFieldValue("paidAmount")) {
            notification.warning({
                message: "Enough Amount Added, Cannot Add More Payment Method",
            });
            return;
        }

        const lastData = formData[formData.length - 1];
        if (!lastData.bankId) {
            notification.warning({message: "Please Choose Bank"});
            return;
        }
        if (!lastData.paymentType) {
            notification.warning({message: "Please Choose Payment Type"});
            return;
        }
        if (!lastData.amount) {
            notification.warning({message: "Please Enter Amount"});
            return;
        }

        setFormData([
            ...formData,
            {bankId: "", paymentType: "", amount: "", transactionDetails: ""},
        ]);

        setShowDeleteButtons([...showDeleteButtons, true]);
    };

  

    const {getFieldValue} = props.form;
    const navigate = useNavigate();
    let dispatch = useDispatch();
    let [customerId, setCustomerId] = useState("");
    let [visitNumber, setVisitNumber] = useState();
    let [visitData, setVisitData] = useState({});
    let [clientId, setClientId] = useState("");
    const paidToUserId = getFieldValue("paidToUserId");
    const [banks, setBanks] = useState([]);
    const [saleCategory, setSaleCategory] = useState([]);
    const [ProductReport, setProductReport] = useState([]);

    const updateProductReport = (newProductReport) => {
        setProductReport(newProductReport);
    };

    const [requestId, setRequestId] = useState(null);
    const [visitId, setVisitId] = useState("");
    const [customerState, setCustomerState] = useState("");
    const [customerName, setCustomerName] = useState("");

    //credit note useStates
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [creditNoteData, setCreditNoteData] = useState([]);
    const [CreditNoteBalance, setCreditNoteBalance] = useState();
    const [creditNote_id, setCreditNote_id] = useState();
    const [showcreditNoteButton, setCreditNoteButton] = useState(false);

    
    // const getCreditNoteOfCustomer = async (customerId) => {
    //     const data = await fetchCreditNoteByCustomerId(customerId);
    //     if (data) {
    //         setCreditNoteData(data.data);
    //         props.form.setFieldsValue({
    //             creditNoteBalance: 0,
    //             remainingAmount: getFieldValue("totalAmount"),
    //         });
    //     }
    //     console.log(CreditNoteBalance, "creditNote_id");
    //     if (data.data.length != 0) {
    //         setCreditNoteButton(true);
    //     } else {
    //         setCreditNoteButton(false);
    //     }
    // };

    // useEffect(() => {
    //     getCreditNoteOfCustomer(customerId);
    // }, [customerId]);

    useEffect(() => {
        const apiRequest123 = (params = {}) => {
            return new Promise(async (resolve) => {
                params._id = clientId;
                const data = await fetchCustomer({...params});
                setCustomerName(data.data.data[0].name);
            });
        };
        if (clientId) {
            apiRequest123();
            setCustomerId(clientId);
            
        }
    }, [clientId]);

    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        if (x.get("id")) {
            setIsModal2Open(true);
        }
        setRequestId(x.get("id"));
        setClientId(x.get("clientId"));
        setVisitNumber(x.get("visitNumber"));
    }, []);

    useEffect(() => {
        let x = new URLSearchParams(window.location.search);
        setVisitId(x.get("visitID"));
    }, []);

    let netAmount = 0;
    let quantity = 0;
    let balance = 0;

console.log(customerName,"customerName")
    console.log(customerId, "custoe")
    const calNetAmount = () => {
        if (ProductReport.length == 0) {
            props.form.setFieldsValue({
                totalAmount: 0,
                balance: 0,
                quantity: 0,
                creditNoteBalance: 0,
                remainingAmount: 0,
            });
        }
        ProductReport.forEach((y) => {
            let paidAmount = getFieldValue("paidAmount");
            paidAmount = paidAmount ? fixed2Digit(paidAmount) : 0;
            netAmount += Number(y.netAmount);
            quantity += Number(y.quantity);
            balance = paidAmount ? netAmount - paidAmount : netAmount;

            if (balance < 0) {
                balance = "Not valid Paid Amount";

                props.form.setFieldsValue({
                    totalAmount: netAmount ? fixed2Digit(netAmount) : 0,
                    quantity: quantity,
                    balance: 0,
                });
            } else {
                props.form.setFieldsValue({
                    totalAmount: netAmount.toFixed(2),
                    quantity: quantity,
                    balance: balance ? balance.toFixed(2) : 0,
                });
            }
        });
    };

    // useEffect(() => {
    //     if (CreditNoteBalance) {
    //         const finalBalance = getFieldValue("balance") - CreditNoteBalance;
    //         const remainingAmount = getFieldValue("totalAmount") - CreditNoteBalance;
    //         props.form.setFieldsValue({
    //             balance: finalBalance,
    //             remainingAmount: remainingAmount,
    //         });
    //     }
    // }, [CreditNoteBalance]);

    // const fetchBanks = async () => {
    //     const {data} = await fetchBank({
    //         type: "bank",
    //         results: 100,
    //         count: 100,
    //     });
    //     setBanks(data);
    // };

    // const fetchSaleFrom = async () => {
    //     const {data} = await fetchSaleCategory({});
    //     setSaleCategory(data);
    // };

    // const fetchVisitByVisitNumber = async (visitNumber) => {
    //     const data = await fetchVisitNumber(visitNumber);
    //     if (data) {
    //         setVisitData(data.products);
    //     }
    // };

    // useEffect(() => {
    //     fetchVisitByVisitNumber();
    // }, [visitNumber]);

    // const apiRequest2 = async () => {
    //     return new Promise(async (resolve) => {
    //         try {
    //             console.log(visitId, "this is visit id");
    //             let params = {
    //                 visitId: visitId,
    //             };
    //             const data = await fetchVisitAllDetailsFxn({...params});
    //             setVisitData(data.prescriptionDetails.products);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     });
    // };

    // useEffect(() => {
    //     apiRequest2();
    // }, [visitId]);

    useEffect(() => {
        props.form.setFieldsValue({
            paidAmount: 0,
            paymentType: "None",
            salesDate: moment(),
           
            creditNoteBalance: 0,
            remainingAmount: 0,
        });
        if(customerState)
        {
            props.form.setFieldsValue({
               
                stateOfSupply: customerState,
               
            });
        }
     
    }, [customerState]);

    useEffect(() => {
        calNetAmount();
    }, [ProductReport, getFieldValue("paidAmount")]);

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                let findStateCode = _.find(States, (item) => {
                    return item.name === valData.state;
                });
                if (findStateCode) {
                    valData.stateCode = findStateCode.stateCode;
                }
                valData.productReports = ProductReport;
                valData.customerId = customerId;
          
                valData.requestId = requestId;
             
                if (creditNote_id && CreditNoteBalance) {
                    valData.creditNoteId = creditNote_id;
                    valData.creditNoteBalance = CreditNoteBalance;
                }

                console.log(valData, "valData");

                const sumFormDataAmount = formData.reduce(
                    (sum, item) => sum + parseFloat(item.amount) || 0,
                    0
                );

                if (ProductReport.length == 0) {
                    notification.error({
                        message: "Please Add Products",
                    });
                    return;
                }

                const totalAmount = valData.totalAmount;
                const paidAmount = valData.paidAmount;
                if (paidAmount < 0) {
                    notification.error({
                      message: "Paid Amount cannot be negative",
                    });
                    return;
                  }
                
                if (!valData.customerId) {
                    notification.warning({message: "Please Choose Client"});
                    return;
                }
                if (!valData.stateOfSupply) {
                    notification.warning({message: "Please Add State of Supply"});
                    return;
                }
                // if (!valData.customerId) {
                //     notification.warning({message: "Please Choose Client"});
                //     return;
                // }

                console.log(
                    parseFloat(valData.paidAmount) < sumFormDataAmount,
                    "totalAmount"
                );

                let x = await dispatch(addSaleFxn(valData));

                if (x && x.success) {
                    notification.success({message: x.message});
                    setProductReport([]);
                    props.form.setFieldsValue({});
                    setTimeout(() => {
                        navigate("/saleList");
                    }, 1000);
                } else {
                    notification.error({message: x.message});
                }
                props.form.setFieldsValue({
                    stateOfSupply: "",
                    invoiceNumber: "",
                    salesDate: "",
                    totalAmount: "",
                    paidAmount: "",
                    balance: "",
                    paidToUserId: "",
                    paymentType: "",
                    paymentDetails: "",
                });
                setCustomerId("");
            }
        });
    };
    console.log(customerName, "customerName")
    let inputTypes = {
        upperFields: [
            {
                key: "salesDate",
                label: "Date of Sale",
                type: "date",
                span: "col-md-3",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        salesDate: x,
                    });
                },
            },
           
            {
                key: "stateOfSupply",
                label: "State of Supply",
                span: "col-md-3",
                placeholder: "State of supply",
                type: "select",
                options: IndianStates,
                showSearch: true,
              
                keyAccessor: (x) => x.stateCode,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        stateOfSupply: x,
                    });
                },
            },
        ],

        lowerFields: [
            {
                key: "totalAmount",
                label: "Total Amount *",
                span: "col-md-2",
                placeholder: "Total Amount",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        totalAmount: netAmount,
                    });
                },
                disabled: true,
            },
            {
                key: "paidAmount",
                type: "number",
                label: "Received Amount",
                span: "col-md-2",
                placeholder: "Received Amount",
            },
            {
                key: "balance",
                label: "Balance *",
                span: "col-md-2",
                placeholder: "Balance",
                onChange: (x) => {
                    props.form.setFieldsValue({
                        balance: balance,
                    });
                },
                disabled: true,
            },
        ],
    };

    console.log(customerState, "dataaaaaaa")

    return (
        <PageHeader title={"Add Sale"}>
            <div className="card">
                <div className="card-body">
                    <div className={"row"}>
                        {clientId ? (
                            <div className={"row"}>
                                <div className={"col-md-3 mb-3"}>
                                    <InputBox title={"Client"}>
                                        <input
                                            style={{height: "45px", color: "black"}}
                                            disabled
                                            className={"form-control"}
                                            type="text"
                                            value={customerName}
                                        />
                                    </InputBox>
                                </div>
                            </div>
                        ) : (
                            <div className={"col-md-3 mb-3"}>
                                <ChooseCustomer
                                
                                    customerId={customerId}
                                    callback={(clientData) => {
                                        if (clientData && clientData._id) {
                                            setCustomerId(clientData._id);
                                            setCustomerState(clientData.billingState)
                                        }
                                    }}
                                />
                            </div>
                        )}

                        {visitId ? (
                            <div className={"col-md-3 mb-3"}>
                                <InputBox title={"Visit Number"}>
                                    <div className="input-group">
                                        <input
                                            className={"form-control"}
                                            type="number"
                                            value={visitNumber}
                                            placeholder="Visit number"
                                            onChange={(e) => setVisitNumber(e.target.value)}
                                        />
                                       
                                    </div>
                                </InputBox>
                            </div>
                        ) : null}
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <div className="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.upperFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>
                            <div className={"row"} key={getFieldValue("stateOfSupply")}>
                                <div className={"col-md-12 mt-4"}>
                                    <ItemListComponent
                                        isModal2Open={isModal2Open}
                                        setIsModal2Open={setIsModal2Open}
                                        visitData={visitData}
                                        requestId={requestId}
                                        visitId={visitId}
                                        ProductReport={ProductReport}
                                        customerId={customerId}
                                        updateProductReport={updateProductReport}
                                        stateOfSupply={getFieldValue("stateOfSupply")}
                                        companyState={companyStateCode}
                                    />
                                </div>
                            </div>
                            {showcreditNoteButton && ProductReport?.length !== 0 ? (
                                <Button
                                    className="btn btn-gradient-info me-2 mb-4"
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Available Credit Note
                                </Button>
                            ) : null}

                            <div className={"row"}>
                                {inputTypes.lowerFields.map((item, key) => {
                                    return !item.hidden ? (
                                        <div
                                            className={`pb-3 ${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    ) : null;
                                })}
                            </div>

                            {isModalOpen && (
                                <Modal
                                    visible={isModalOpen}
                                    title={"Choose CreditNote"}
                                    onClose={() => setIsModalOpen(false)}
                                >
                                    <div>
                                        <InputBox title={"Credit Note"}>
                                            <select
                                                style={{
                                                    height: "45px",
                                                    width: "500px",
                                                }}
                                                className="form-control"
                                                value={CreditNoteBalance}
                                                required
                                                onChange={(e) => {
                                                    const selectedCreditNoteId = e.target.value;
                                                    const selectedCreditNote = creditNoteData.find(
                                                        (creditNote) =>
                                                            creditNote._id === selectedCreditNoteId
                                                    );

                                                    setCreditNoteBalance(selectedCreditNote.saleBalance);
                                                    setCreditNote_id(selectedCreditNoteId);
                                                    setIsModalOpen(false);
                                                    props.form.setFieldsValue({
                                                        creditNoteBalance: selectedCreditNote.saleBalance,
                                                    });
                                                }}
                                            >
                                                <option value="">Select Credit Note</option>
                                                {/* {creditNoteData
                  ?.filter(creditNote => creditNote.saleBalance !== 0)
                  .map((creditNote) => (
                    <option value={creditNote._id} key={creditNote._id}>
                      {`${creditNote.saleBalance}(${creditNote.invoiceNumber})`}
                    </option>
                  ))} */}

                                                {creditNoteData &&
                                                creditNoteData.some(
                                                    (creditNote) => creditNote.saleBalance !== 0
                                                ) ? (
                                                    <>
                                                        {creditNoteData
                                                            .filter(
                                                                (creditNote) => creditNote.saleBalance !== 0
                                                            )
                                                            .map((creditNote) => (
                                                                <option
                                                                    value={creditNote._id}
                                                                    key={creditNote._id}
                                                                >
                                                                    {`${creditNote.saleBalance}(${creditNote.invoiceNumber})`}
                                                                </option>
                                                            ))}
                                                    </>
                                                ) : (
                                                    <option value="" disabled>
                                                        No record found
                                                    </option>
                                                )}
                                            </select>
                                        </InputBox>
                                    </div>
                                </Modal>
                            )}

                           
                        </div>

                        <button type="submit" className="btn btn-primary">
                    Submit
                </button>
                        <HyperLink className=" ms-4 btn btn-danger" link={"/sale"}>
                            Cancel
                        </HyperLink>
                    </Form>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(AddSale);
