
import { apiUrl } from "../../../setting";
export const addSaleUrl = () => {
    return `${apiUrl}/saleProduct`;
};
export const addSaleProductUrl = () => {
    return `${apiUrl}/createSaleProduct`;
};
// export const getsaleProductUrl = () => {
//     return `${apiUrl}/getSaleReport`;
// };
export const getSaleProductListUrl = () => {
    return `${apiUrl}/saleProductList`;
};
export const getSaleUrl = () => {
    return `${apiUrl}/getAllSale`;
};
export const getSingleSaleUrl = (id) => {
    return apiUrl + "/getSingleSale/" + id;
  };
  export const PrintSaleUrl = (id) => {
    return apiUrl + "/printSaleRecord/" + id;
};