import {appAxios as axios} from "../../../request";
import {showLoader, hideLoader} from "../../../actions/loader";
import {loginUrl,otpUrl} from "../apis";
import {notification} from "../../../components/Elements/appUtils";

export const loginFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(loginUrl(), valData);
    dispatch(hideLoader())
    if (data.success) {
        notification.success({message: data.message})
    } 
    return data;
}
export const otpFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(otpUrl(), valData);
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message
        });
    }
    return data;
};

