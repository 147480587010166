import { apiUrl } from "../../../setting";

export const tableListUrl = () => {
  return "/table-list";
};

export const getOldKotUrl = () => {
  return "/getOrderByTableNo";
};

export const getOldKotByOrderIdUrl = () => {
  return "/getOrderByOrderId";
};

export const groupedProductUrl = () => {
  return "/groupedProduct";
};

export const addOrderUrl = () => {
  return apiUrl + "/addOrder";
};

export const saveOrderUrl = () => {
  return apiUrl + "/saveOrder";
};

export const transferTableUrl = () => {
  return apiUrl + "/transferTable";
};

export const OrderPaymentUrl = () => {
  return apiUrl + "/orderPayment";
};

export const getPrintProductsUrl = () => {
  return apiUrl + "/getPrintProducts";
};

export const editKotUrl = () => {
  return apiUrl + "/editKot";
};
export const getCouponDisUrl = () => {
  return apiUrl + "/getCouponByNumber";
};
export const addProductDetailsUrl = () => {
  return `${apiUrl}/addDetails`;
};
export const getSpecialNotesUrl = () => {
  return `${apiUrl}/getSpecialNotesData`;
};
export const singleNoteUrl = (id) => {
  return apiUrl + "/getSingleSpecialNote/" + id;
};
export const updateNoteUrl = () => {
  return `${apiUrl}/updateSpecialNote`;
};
