import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    Form,
    notification,
} from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {addUserFxn, getSingleUserFxn, singleUserFxn, updateUserFxn} from "./actions";
import {vegNonVeg} from "../../components/_utils/_utils";
import {tableListFxn} from "../pos/actions";
import {singleProductFxn} from "../products/actions";
import {getToken} from "../../request";

let initState = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    address: ""
}

function EditUser(props) {
    let dispatch = useDispatch()
    let [state, setState] = useState(initState)
    const [userId, setUserId] = useState("");
    let id;
    let events = {
        _updateState: (data) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    ...data
                }
            })
        }
    }

    const handleSubmit = async (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                valData._id = userId
                if (!valData.name) {
                    notification.warning({message: "Enter Name"});
                    return;
                }
                if (!valData.email) {
                    notification.warning({message: "Choose email"});
                    return;
                }
                console.log(valData,"valdata")
                let x = await dispatch(updateUserFxn({...valData, userType: "captain"}));
                if (x && x.success) {
                }
            }
        });
    };

    const checkUserId = async () => {
        let searchParams = new URLSearchParams(window.location.search);
        id = searchParams.get("_id");
        if (id) {
            getSingleUser(id);
            setUserId(id);
        }
    };
    const getSingleUser = async (id) => {
        let {data, success} = await dispatch(getSingleUserFxn(id), getToken());
        if (success) {
            setTimeout(() => {
                props.form.setFieldsValue({
                    name: data.name,
                    email: data.email,
                    tables: data.tables,
                    address: data.address
                })
            }, 300);
        }
    };

    let [tableList, setTableList] = useState([])
    let loadTables = async () => {
        let {data} = await tableListFxn();
        setTableList(data);
    }
    useEffect(() => {
        loadTables()
    }, [])

    let inputTypes = {
        fields: [
            {
                key: "name",
                label: "Name *",
                span: "col-md-4",
                required: true
            },
            {
                key: "email",
                label: "Email *",
                span: "col-md-4",
                required: true
            },
            {
                key: "tables",
                label: "Tables",
                span: "col-md-4",
                type: "select",
                options: tableList,
                showSearch: true,
                multiple: true,
                keyAccessor: (x) => x.name,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        tables: x,
                    });
                },
            },
            {
                key: "address",
                label: "Address *",
                span: "col-md-12"
            },

        ],
    };

    useEffect(() => {
        checkUserId();
    }, []);

    return (
        <PageHeader title={"Edit User"}>
            <Form onSubmit={handleSubmit}>
                <div className="form-elements-wrapper">
                    <div className="card-body">
                        <div className={"row"}>
                            {inputTypes.fields.map((item, key) => {
                                return !item.hidden ? (
                                    <div className={`${item.span ? item.span : "col-md-6"}`}
                                         key={key}>
                                        <GetEachFormFields {...props.form} item={item}/>
                                    </div>
                                ) : null;
                            })}
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <button type="submit" className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </Form>
        </PageHeader>
    );
}

export default Form.create()(EditUser);
