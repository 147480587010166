import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {kitchenTypes} from "../../components/Elements/appUtils";

const isLoggedIn = localStorage.getItem("token") ? true : false;
let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
const AuthLayout = (props) => {
    let urlPath = "/dashboard"
    if (user && user._id) {
        let {userType} = user;
        if (userType == "captain") {
            urlPath = "/tables";
        } else if (kitchenTypes.includes(userType)) {
            urlPath = "/kotView";
        }
    }

    return isLoggedIn ? (
        <Navigate replace to={urlPath}/>
    ) : (
        <>
            <Outlet/>
        </>
    );
};
export default AuthLayout;
