import React, { useEffect, useState } from "react";
import {
  InputBox,
  Select2,
  Tooltip,
} from "../components/Elements/appUtils";
import _ from "lodash";
import Select from "rc-select";
import { fetchCustomer } from "../containers/customer/action";
// import "../../../components/Elements/Select/index.css";
import "rc-select/assets/index.less";
import AddCustomerDrawer from "./addCustomerDrawer"

import Image from "../add-button.png"
const { Option } = Select;

const  ChooseCustomer = (props) => {
  let {
    callback,
    customerId,
    gstType,
    type,
    groupType,
  
  } = props;

  let [clientId, setClientId] = useState(undefined);
  
  let [arrData, setArrData] = useState([]);
  let [billingState, setBillingState] = useState('');

  let [visible, setVisible] = useState(false);
  const events = {
    loadDefaultClient: async () => {
      if (customerId) {
        let params = {
          results: 20,
          count: 20,
          _id: customerId,
          regExFilters: ["name", "mobile", "email"],
          select: ["name", "mobile"],
        };
        let { data } = await fetchCustomer(params);
      
     

        setArrData(data.data);
      } else {
        setArrData([]);
      }
    },
    fetchClient: async (name) => {
      if (name) {
        let params = {
          results: 20,
          count: 20,
          name: name,
          type: type,
          gstType: gstType,
          groupType: groupType,
          regExFilters: ["name", "mobile", "email"],
          select: ["name", "mobile"],
        };
        let { data } = await fetchCustomer(params);
       console.log(data, "fetchclient")
        setArrData(data.data);
      
      } else {
        setArrData([]);
      }
    },

    handleChange: (clientId) => {
      let item = _.find(arrData, (value) => {
        return value._id == clientId;
      });
  
      setClientId(clientId);
      callback(item);
    },
  };
  useEffect(() => {
    events.loadDefaultClient();
  }, [customerId]);

  let addCustomerCallback=(data)=>{
    setVisible(false);
    if(data && data.name){
      console.log(data, "checkdataforstateofsupply")
      setArrData([data]);
      setClientId(data._id);
      setBillingState(data.billingState)
    callback(data)
    }
  }
console.log(billingState, "billingState")

  return (
    <>
   <InputBox title={"Choose Customer"}>
        <div className={"rowFlex"}>
          <Select
            showSearch={true}
            onSearch={events.fetchClient}
            filterOption={false}
            autoClearSearchValue={true}
            allowClear={true}
            value={clientId}
            showArrow={true}
            onChange={events.handleChange}
            placeholder="Choose Customer"
            className={`rc-select rc-select1`}
            choiceTransitionName="rc-select-selection__choice-zoom"
            style={{ width: "100%" }}
            dropdownRender={(menu) => {
              return <div key={arrData}>{menu}</div>;
            }}
          >
            {arrData && arrData.length
              ? arrData.map((item, index) => {
                  return (
                    <Option key={item._id} value={item._id}>
                    
              {`${item.name}`}
                    </Option>
                  );
                })
              : null}
          </Select>
          <Tooltip title={"Add Customer"}>
            <a
              className={"plus_btn "}
              onClick={() => {
                setVisible(true);
              }}
              style={{marginTop:"8px", marginLeft:"8px"}}
            >
             <img src={Image} style={{height:"20px", width: "25px"}}></img>
            </a>
          </Tooltip>
        </div>
      </InputBox>
      {visible ? (
        <AddCustomerDrawer
          visible={visible}
          groupType={groupType}
          onClose={addCustomerCallback}
        />
      ) : null}
    </>
  );
};
export default ChooseCustomer;
