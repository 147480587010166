import {apiUrl} from "../../../setting";

export const getCouponDataUrl = () => {
    return `/getCouponData`;
};
export const addCouponUrl = () => {
    return `/addCoupon`;
};
export const editCouponUrl = () => {
    return `/updateCoupon`;
};
export const deleteCouponUrl = () => {
    return `/deleteCoupon`;
};
export const getCouponUrl = (couponId) => {
    return apiUrl + "/getSingleCoupon/" + couponId;
};
