// import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import { loginFxn } from "./actions";
// import { notification } from "../../components/Elements/appUtils";
// import { Socket } from "../../socket";
// import { useNavigate } from "react-router-dom";
// import { getItemLocalstorage } from "../../components/_utils/_utils";

// let initState = {
//   email: "",
//   password: "",
// };
// const Login = () => {
//   const navigate = useNavigate();

//   let dispatch = useDispatch();
//   let [state, setState] = useState(initState);
//   let events = {
//     _updateState: (data) => {
//       setState((prevState) => {
//         return {
//           ...prevState,
//           ...data,
//         };
//       });
//     },
//     handleSubmit: async (e) => {
//       e.preventDefault();
//       if (!state.email) {
//         notification.warning({ message: "Enter email address" });
//         return;
//       }
//       if (!state.password) {
//         notification.warning({ message: "Enter password" });
//         return;
//       }
//       let x = await dispatch(loginFxn(state));

//       if (x.success) {

//         localStorage.setItem("token", x.token);
//         localStorage.setItem("user", JSON.stringify(x.user));

//         dispatch({
//           type: "SET_CURRENT_USER",
//           user: x.user,
//         });

//         if (!Socket.connected) {
//           Socket.connect();
//         }
//         const userType = getItemLocalstorage("user")["userType"];

//         setTimeout(() => {
//           if (userType == "captain") {
//             window.location.href = "/tables";
//           } else if (userType == "kitchen") {
//             window.location.href = "/kotView";
//           } else {
//             window.location.href = "/dashboard";
//           }
//         }, 1000);
//       }
//     },
//   };

//   return (
//     <>
//       <div class="main-wrapper">
//         <div class="account-content">
//           <div class="login-wrapper">
//             <div class="login-content">
//               <div class="login-userset">
//                 <div class="login-logo logo-normal">
//                   <img src={"../assets/logos/thrones_logo.png"} alt="img" />
//                 </div>
//                 <a href="../../index.html" class="login-logo logo-white">
//                   <img src={"../assets/logos/thrones_logo.png"} alt="" />
//                 </a>
//                 <div class="login-userheading">
//                   <h3>Sign In</h3>
//                   <h4>Please login to your account</h4>
//                 </div>
//                 <form onSubmit={events.handleSubmit}>
//                   <div className="form-login">
//                     <label>Email</label>
//                     <div className="form-addons">
//                       <input
//                         type="text"
//                         placeholder="Enter your email address"
//                         value={state.email}
//                         onChange={({ target }) => {
//                           events._updateState({ email: target.value });
//                         }}
//                       />
//                       <img src="../assets/img/icons/mail.svg" alt="img" />
//                     </div>
//                   </div>
//                   <div className="form-login">
//                     <label>Password</label>
//                     <div className="pass-group">
//                       <input
//                         type="password"
//                         value={state.password}
//                         onChange={({ target }) => {
//                           events._updateState({ password: target.value });
//                         }}
//                         className="pass-input"
//                         placeholder="Enter your password"
//                       />
//                       <span className="fas toggle-password fa-eye-slash"></span>
//                     </div>
//                   </div>

//                   <div className="form-login">
//                     <button className="btn btn-login">Sign In</button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div class="login-img">
//               <img src="../assets/img/login.jpg" alt="img" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Login;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginFxn } from "./actions";
import { notification } from "../../components/Elements/appUtils";
import { Socket } from "../../socket";
import { useNavigate } from "react-router-dom";
import { getItemLocalstorage } from "../../components/_utils/_utils";
import {kitchenTypes} from "../../components/Elements/appUtils";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
    otp: "",
  });

  const [showOTP, setShowOTP] = useState(false);
  const [otp1, setOtp] = useState('');
  const [userType, setUserType] = useState('');
  let otpMatched = false
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (showOTP && !state.otp) {
      notification.warning({ message: "Enter OTP" });
      return;
    }
    if (userType == 'admin' && state.otp == otp1){
      otpMatched = true
    }
    else if(userType == 'admin' && state.otp != otp1){
      otpMatched = false
      notification.warning({ message: "Incorrect OTP" });
      return;
    }
    state.otpMatched = otpMatched

    let x = await dispatch(loginFxn(state));
    if (x.otp){
      setOtp(x.otp)
    }
    if (x.userType){
      setUserType(x.userType)
    }
    if (x.success) {
      localStorage.setItem("token", x.token);
      localStorage.setItem("user", JSON.stringify(x.user));
      dispatch({ type: "SET_CURRENT_USER", user: x.user });

      if (!Socket.connected) {
        Socket.connect();
      }
      const userType = x.user["userType"];
      setTimeout(() => {
        // if (userType === "captain") {
        //     window.location.href = "/tables";
        // } else if (kitchenTypes.includes(userType)) {
        //     window.location.href = "/kotView";
        // } else {
        //     window.location.href = "/dashboard";
        // }
        window.location.href = "/dashboard";

      }, 1000);
    }
     else if (x.message === "Enter OTP.") {
      setShowOTP(true);
    }
    else{
      notification.error({message: x.message || "error"})
    }
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="account-content">
          <div className="login-wrapper">
            <div className="login-content">
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <img src={"../assets/logos/thrones_logo.png"} alt="img" />
                </div>
                <a href="../../index.html" className="login-logo logo-white">
                  <img src={"../assets/logos/thrones_logo.png"} alt="" />
                </a>
                <div className="login-userheading">
                  <h3>Sign In</h3>
                  <h4>Please login to your account</h4>
                </div>
                <form onSubmit={handleSubmit}>
                  {!showOTP && (
                    <>
                      <div className="form-login">
                        <label>Email</label>
                        <div className="form-addons">
                          <input
                            type="text"
                            placeholder="Enter your email address"
                            value={state.email}
                            onChange={({ target }) =>
                              setState((prevState) => ({
                                ...prevState,
                                email: target.value,
                              }))
                            }
                          />
                          <img src="../assets/img/icons/mail.svg" alt="img" />
                        </div>
                      </div>
                      <div className="form-login">
                        <label>Password</label>
                        <div className="pass-group">
                          <input
                            type="password"
                            value={state.password}
                            onChange={({ target }) =>
                              setState((prevState) => ({
                                ...prevState,
                                password: target.value,
                              }))
                            }
                            className="pass-input"
                            placeholder="Enter your password"
                          />
                          <span className="fas toggle-password fa-eye-slash"></span>
                        </div>
                      </div>
                    </>
                  )}
                  {showOTP && (
                    <div className="form-login">
                      <label>OTP</label>
                      <input
                        type="text"
                        value={state.otp}
                        onChange={({ target }) =>
                          setState((prevState) => ({
                            ...prevState,
                            otp: target.value,
                          }))
                        }
                        placeholder="Enter OTP"
                      />
                    </div>
                  )}

                  <div className="form-login">
                    <button className="btn btn-login">Sign In</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="login-img">
              <img src="../assets/img/login.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
