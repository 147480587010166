import { apiUrl } from "../../../setting";

export const pendingKotUrl = () => {
    return '/getPendingKot'
}
export const updateKotStatusUrl = () => {
    return '/updateKotStatus'
}


export const updatePriorityUrl = () => {
    return '/updatePriority'
}

export const getAllKotUrl = () => {
    return `${apiUrl}/kotList`;
};


export const updateStatusKotUrl = () => {
    return apiUrl + "/updateStatusKot";
};