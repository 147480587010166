import {
    getSaleUrl,
    addSaleUrl,
    editSaleUrl,
    getCustomerDataUrl,
    getbatchRecordByProductIdUrl,
    invoiceUpdateUrl,
    addSaleProductUrl,
    getSaleProductListUrl,
 
  } from "../api";
  import { getToken } from "../../../request";
  import { hideLoader, showLoader } from "../../../actions/loader";
  import notification from "../../../components/Elements/Notification";
  import axios from "axios";
 
  
export const fetchSale = async (valData) => {
  let config = {
      params: {...valData},
      ...await getToken()
  }
  const {data} = await axios.get(getSaleUrl(), config);
  return data;
};
export const fetchSaleProduct = async (valData) => {
  let config = {
      params: {...valData},
      ...await getToken()
  }
  const {data} = await axios.get(getSaleProductListUrl(), config);
  return data;
};

  
  export const addSaleFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    const { data } = await axios.post(addSaleUrl(), valData, getToken());
    dispatch(hideLoader());
    if (!data.error) {
    } else {
      notification.error(data.message || "Error");
    }
    return data;
  };
  export const addSaleProductFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addSaleProductUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};
  
