import React, {useState, useRef, useEffect} from "react";
import {
    InputBox,
    Form,
    Table,
    notification, Tooltip, Popconfirm,
} from "../../components/Elements/appUtils";
import {addVariantUrl} from "./api";
import {deletedVariantFxn, FetchVariants} from "./actions";
import {FetchCatgory} from "./actions";
import PageHeader from "../../components/Elements/pageHeader";
import axios from "axios";
import {GetEachFormFields} from "../../components/_utils/formUtils";
import {useNavigate} from "react-router-dom";
import { getToken } from "../../request";
import {useDispatch} from "react-redux";

function AddVariant(props) {
    let tableRef = useRef();
    let dispatch = useDispatch()
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState([])
    const [name, setName] = useState('')
    const [deleted, setDeleted] = useState('false');


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.name) {
                    notification.warning({message: "Enter name"})
                    return
                }

                const response = await axios.post(addVariantUrl(), valData, getToken());
                if (response.data.success) {
                    props.form.setFieldsValue({
                        name: "",
                        category: [],
                        categoryType: []

                    });
                    notification.success({
                        message: response.data.message || "Success",
                    });

                    tableRef.current.reload();
                } else {
                    notification.error({message: response.data.message});
                }
            }
        });
    };
    useEffect(() => {
        handleState();

    }, []);

    const handleState = async () => {
        let x = await FetchCatgory({results: 1000});
        setAllCategory(x.data)
    };
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                if (name){
                    params.name = name
                    params.results = 1000
                    params.count = 1000
                }
                if (deleted == 'true') {
                    params.deleted = true;
                    params.page = 1
                }else{
                    params.deleted = { $exists: false }
                }
                const {data} = await FetchVariants({...params});
                console.log(data)
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => <div>
                {
                    record.category.map((item) => {
                        return (
                            <>
                                <div>{item.name}</div>
                            </>
                        )
                    })
                }

            </div>
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, item) => {
                return (
                    <>
                        <a className={'empty_btn'} onClick={() => {
                            navigate(`/editVariant?_id=${item._id}`)
                        }}>
                            <i className={'fa fa-edit far'}/>
                        </a>
                        {
                            item.deleted != true ?
                            <Tooltip title={"Delete"}>
                                <Popconfirm
                                    title={"Are your sure, you want to Delete Variant?"}
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => {
                                        deleteVariant(item._id, true);
                                    }}>
                                    <i style={{height: "20px", width: "20px",marginLeft:'20px'}}  className={'fa fa-trash far'}/>
                                </Popconfirm>
                            </Tooltip>:<Tooltip title={"Undo Delete"}>
                                    <Popconfirm
                                        title={"Are your sure, you want to Undo Delete Variant?"}
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => {
                                            deleteVariant(item._id, false);
                                        }}>
                                        <i style={{height: "20px", width: "20px",marginLeft:'20px'}}  className={'fa fa-undo far'}/>
                                    </Popconfirm>
                                </Tooltip>
                        }
                    </>
                );
            },
        },
    ];

    const deleteVariant = async (id, deleted) => {
        let data = {}
        data.id = id
        data.deleted = deleted
        let resp = await dispatch(deletedVariantFxn(data));
        if (resp && resp.success) {
            tableRef.current.reload();
        }
    };

    let inputTypes = {

        fields: [
            {
                key: "name",
                label: "Variant Name",
                span: "col-md-4",
                placeholder: "Variant Name",
                type: "text",

            },
            {
                key: "category",
                label: "Select Category",
                placeholder: "Please Choose Menu",
                type: "select",
                showStar: true,
                span: "col-md-4",
                options: allCategory,
                multiple: true,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.name}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        category: x,
                    });
                },
            },
        ]

    };

    const search = async () => {
            tableRef.current.reload()
    };

    return (
        <PageHeader title={"Variants"}>
            <Form onSubmit={handleSubmit}>
                <div class="card">
                    <div class="card-body">
                        <div class="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <div
                                            className={`${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>

                        <button className="btn btn-success">
                            SUBMIT
                        </button>
                    </div>
                </div>
            </Form>
            <div className="row mb-3">
                <div className="col-md-2">
                    <input
                        type="text"
                        className="form-control search-filters"
                        name="name"
                        placeholder="Search by Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                    />
                </div>

                <div className="col-md-2">
                    <select className="form-control search-filters"
                            onChange={(e) => {
                                setDeleted(e.target.value)
                                setTimeout(() => {
                                    tableRef.current.reload()
                                }, 300);
                            }}
                            value={deleted}>
                        <option value={'false'}>Not Deleted</option>
                        <option value={'true'}>Deleted</option>
                    </select>
                </div>

                <div className="col-md-2">
                    <div className="search-wrap">
                        <button className="btn btn-info" onClick={search}>
                            Search
                        </button>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(AddVariant);
