import React, { useEffect, useState} from "react";
import {useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  Card,
  Form,
  HyperLink,
  InputBox,
  notification,
} from "../../components/Elements/appUtils";
import { UnitCodeArr , gstRate} from "../../setting";
import PageHeader from "../../components/Elements/pageHeader";
import {Boolean, vegNonVeg} from "../../components/_utils/_utils";
import {GetEachFormFields} from "../../components/_utils/formUtils";
// import { FetchBrand, addProductFxn, getCategory } from "./actions";

import {FetchCatgory, FetchVariants} from "../variants/actions";
import { addSaleProductFxn } from "./action";

// import { getItemLocalstorage } from "../../components/_utils/_utils";

function AddSaleProduct(props) {
 
 

 
  const {getFieldValue} = props.form;
  let {onClose = null, onSubmit} = props;

  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [variant, setVariant] = useState([]);

  const api = async () => {
    let x = await FetchCatgory({results: 500});
    setCategory(x.data)
  };

 

  useEffect(() => {
    api();
  
  }, []);

  const handleSubmit = async (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
        if (!valData.name) {
          notification.warning({ message: "Enter Name" });
          return;
        }
      
    
        if (!valData.price) {
          notification.warning({ message: "Enter Price" });
          return;
        }
        if (!valData.gst) {
          notification.warning({ message: "Choose GST" });
          return;
        }
      
       /* if (!(productFile && productFile.name)) {
          notification.warning({
            message: "Choose select Product-file",
          });
          return;
        }*/
        // if (formData.length != 1) {
        //   valData.variants = formData;
        // }
       
      
        let x = await dispatch(addSaleProductFxn(valData));
        if (x && x.success) {
          props.form.setFieldsValue({});
          if (onClose) {
            //window.location.reload();
            onSubmit()
            onClose();
          }
          props.form.setFieldsValue({
            name: "",
          
            code: "",
            price: "",
            unitCode: "",
            hsnCode: "",
            gst: ""

          });
       

        //   setTimeout(() => {
        //     navigate("/productList");
        //   }, 1000);
        }
      }
    });
  };

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-4",
        // placeholder: "Name",
      },
   
   
      {
        key: "hsnCode",
        label: "HSN Code *",
        type: "text",
        span: "col-md-4",
        placeholder: "HSN Code",
      },
      {
        key: "price",
        label: "Price *",
        type: "number",
        span: "col-md-4",
        placeholder: "Price",
        hidden: getFieldValue("type") === "Product",
      },
      {
        key: "unitCode",
        label: "Unit *",
        span: "col-md-4",
        placeholder: "Unit Code",
        type: "select",
        options: UnitCodeArr,
        showSearch: true,
        keyAccessor: (x) => x.code,
        valueAccessor: (x) => `${x.name} (${x.code})`,
        onChange: (x) => {
          props.form.setFieldsValue({
            unitCode: x,
          });
        },
        
      },
    
      {
        key: "gst",
        label: "GST Rate *",
        span: "col-md-4",
        placeholder: "GST Rate",
        type: "select",
        options: gstRate,
        showSearch: true,
        keyAccessor: (x) => x.code,
        valueAccessor: (x) => `${x.number}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            gst: x,
          });
        },
        // required: true,
      },

    
    ],
  };

  return (
      <PageHeader title={"Add Sale Product"}>
        <Form >
          <div className="form-elements-wrapper">
            <div className="card-body">
              <div className={"row"}>
                {inputTypes.fields.map((item, key) => {
                  return !item.hidden ? (
                      <div
                          className={`${item.span ? item.span : "col-md-6"}`}
                          key={key}
                      >
                        <GetEachFormFields {...props.form} item={item}/>
                      </div>
                  ) : null;
                })}
              </div>
            </div>
       
          </div>

          <button type="submit" onClick={handleSubmit} className="btn btn-primary">
            Submit
          </button>
          <HyperLink className=" ms-4 btn btn-danger" link={"/productlist"}>
            Cancel
          </HyperLink>
        </Form>
      </PageHeader>
  );
}

export default Form.create()(AddSaleProduct);
