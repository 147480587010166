import React, {useState, useRef, useEffect} from "react";
import { Form, notification , Table} from "../components/Elements/appUtils";

import { addProductDetailsUrl } from "./pos/apis";
import { FetchSpecialNotes } from "./pos/actions";
import PageHeader from "../components/Elements/pageHeader";
import axios from "axios";

import {GetEachFormFields} from "../components/_utils/formUtils";

import {useNavigate} from "react-router-dom";
import { getToken } from "../request";

function SpecialNote(props) {
    let tableRef = useRef();
    const [formData, setFormData] = useState({
        specialNote: "",
    });
    const navigate = useNavigate();
    const [allCategory, setAllCategory] = useState([])
    const handleChange = (e, fieldName) => {
        const {value} = e.target;
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };


    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();
        form.validateFields(async (err, valData) => {
            if (!err) {
                if (!valData.specialNote) {
                    notification.warning({message: "Enter Special Note"})
                    return
                }

                const response = await axios.post(addProductDetailsUrl(), valData, getToken());
                if (response.data.success) {
                    props.form.setFieldsValue({
                        specialNote: "",
                     

                    });
                    notification.success({
                        message: response.data.message || "Success",
                    });
                    tableRef.current.reload();
                
                } else {
                    notification.error({message: response.data.message});
                }
            }
        });
    };
 
   
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await FetchSpecialNotes({...params});
                console.log(data, "data")
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const columns = [
        {
            title: "Special Note",
            dataIndex: "specialNote",
            key: "specialNote",
        },
       
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (v, item) => {
                return (
                    <>
                         <a className={'empty_btn'} onClick={() => {
                            navigate(`/editSpecialNote?_id=${item._id}`)
                        }}>
                            <i className={'fa fa-edit far'}></i>
                        </a>

                    </>
                );
            },
        },
    ];

    let inputTypes = {

        fields: [
            {
                key: "specialNote",
                label: "Special Note",
                span: "col-md-4",
                placeholder: "Special Note",
                type: "text",

            },
           
        ]

    };

    return (
        <PageHeader title={"Special Note"}>
            <Form onSubmit={handleSubmit}>
                <div class="card">
                    <div class="card-body">
                        <div class="form-elements-wrapper">
                            <div className={"row"}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <div
                                            className={`${item.span ? item.span : "col-md-6"}`}
                                            key={key}
                                        >
                                            <GetEachFormFields {...props.form} item={item}/>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>

                        <button className="btn btn-success">
                            SUBMIT
                        </button>
                    </div>
                </div>
            </Form>
            <div class="row">
                <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <Table apiRequest={apiRequest} columns={columns} ref={tableRef}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageHeader>
    );
}

export default Form.create()(SpecialNote);
