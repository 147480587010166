import React, { useState } from "react";
import { Drawer } from "../components/Elements/appUtils";
import AddCustomerComponentPos from "./addCustomerComponentPos";
import ChooseCustomerPos from "./chooseCustomerPos";

const AddCustomerDrawerPos = (props) => {
  const [customerId, setCustomerId] = useState("");
  let { visible, onClose, onSubmit } = props;
  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        width="600px"
        title={"Add Customer"}
      >
        <AddCustomerComponentPos onClose={onClose} onSubmit={onSubmit} />
        <div>
          <h2>Search Customer</h2>
          <ChooseCustomerPos
            customerId={customerId}
            callback={(clientData) => {
              if (clientData && clientData._id) {
                setCustomerId(clientData._id);
                onSubmit(clientData)
              }
            }}
          />
        </div>
      </Drawer>
    </>
  );
};
export default AddCustomerDrawerPos;
