import React, { Suspense } from "react";

import { Navigate, Outlet } from "react-router-dom";
import PrintDesign from "../../containers/print/printDesign";

export default function PrintLayout(props) {
  <>
   
      <PrintDesign />
   
  </>;
}
