import React, { useEffect, useState } from "react";
import { Form, HyperLink } from "./Elements/appUtils";
import { useDispatch } from "react-redux";
import { GetEachFormFields } from "./_utils/formUtils";
import {
  States,
  gstTypeList,
  Countries,
  getStateByCountry,
} from "./_utils/appUtils";
import {addCustomerFxn} from "../containers/customer/action"
import _ from "lodash";

import {useNavigate} from "react-router-dom";
import {notification} from "./Elements/appUtils";

const AddCustomerComponent = (props) => {
  let {onClose=null, groupType, onSubmit}= props;

  console.log(onSubmit, "onSubmit")
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [shippingStateList, setShippingStateList] = useState([]);
  const [copyBillingAddress, setCopyBillingAddress] = useState(false);
  const { getFieldValue } = props.form;
  let dispatch = useDispatch();
  console.log(onClose, "onCLose")
//   useEffect(() => {
  
//     let resp = getStateByCountry("India");
//       setStateList(resp);
//       setShippingStateList(resp);
//   }, []);

const events = {
 
  getState: (countryName) => {
    let resp = getStateByCountry(countryName);
    setStateList(resp);
  },
  getShippingState: (countryName) => {
    let resp = getStateByCountry(countryName);
    setShippingStateList(resp);
  },
};
useEffect(() => {
  props.form.setFieldsValue({
     
      gstType: "Unregistered",
      country: "India",
      shippingCountry: "India",
  });
  let resp = getStateByCountry("India");
    setStateList(resp);
    setShippingStateList(resp);
}, []);


  const handleSubmit = (e) => {
    const { form } = props;
    e.preventDefault();
    form.validateFields(async (err, valData) => {
      if (!err) {
     
        let findStateCode = _.find(States, (item) => {
          return item.name === valData.state;
        });
        if (findStateCode) {
          valData.stateCode = findStateCode.stateCode;
        }
        if (!valData.name) {
          notification.warning({ message: "Please Enter Name" });
          return;
        }
        if (!valData.gstType) {
          notification.warning({ message: "Please Select GST Type" });
          return;
        }
       
      
     
      
        if (!valData.shippingState) {
          notification.warning({ message: "Please Select Shipping State" });
          return;
        }
        if (!valData.shippingCity) {
          notification.warning({ message: "Please Select Shipping city" });
          return;
        }
        if (!valData.shippingPincode) {
          notification.warning({ message: "Please Select Shipping Pincode" });
          return;
        }
        if (!valData.shippingAddress) {
          notification.warning({ message: "Please Select Shipping Address" });
          return;
        }

      
        let x = await dispatch(addCustomerFxn(valData));
      
        if (x && x.success) {
          console.log(x.addCustomer, "Cvdsfds")
          if (onClose) {
            onClose(x.addCustomer)
          }
          props.form.setFieldsValue({
            name: "",
            mobileNo: "",
            gstType: "",
            billingAddress: "",
          
            billingState: "",
            billingCity: "",
            billingPincode: "",
         
           
            shippingState: "",
            shippingCity: "",
            shippingPincode: "",
            shippingAddress: "",
          });
         
        }
      }
    });
  };

//   const events = {
//     fetchGroups: async () => {
//       let params = {
//         // results: 100,
//         // count: 100,
//         type: "CLIENT",
//       };
      
//     },
//     getState: (countryName) => {
//       let resp = getStateByCountry(countryName);
//       setStateList(resp);
//     },
//     getShippingState: (countryName) => {
//       let resp = getStateByCountry(countryName);
//       setShippingStateList(resp);
//     },
//   };

//   useEffect(() => {
//     events.fetchGroups();
//   }, []);

  let inputTypes = {
    fields: [
      {
        key: "name",
        label: "Name *",
        span: "col-md-6",
        placeholder: "Name",
      },
      {
        key: "mobileNo",
        label: "Mobile No *",
        span: "col-md-6",
        placeholder: "Mobile No",
      },
     
      {
        key: "gstType",
        label: "GST Type *",
        span: "col-md-6",
        placeholder: "GST Type",
        type: "select",
        options: gstTypeList,
        showSearch: true,
        keyAccessor: (x) => x.value,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          console.log(x);
          props.form.setFieldsValue({
            gstType: x,
          });
        },
      },
      {
        key: "gstIn",
        label: "GSTIN",
        span: "col-md-6",
        placeholder: "GSTIN",
        hidden: getFieldValue("gstType") !== "Registered",
        required: getFieldValue("gstType") === "Registered",
      },
      // {
      //   key: "country",
      //   label: "Country *",
      //   span: "col-md-3",
      //   placeholder: "Country",
      //   type: "select",
      //   options: Countries,
      //   showSearch: true,
      //   keyAccessor: (x) => x.name,
      //   valueAccessor: (x) => `${x.name}`,
      //   onChange: (x) => {
      //     props.form.setFieldsValue({
      //       country: x,
      //     });
      //     events.getState(x);
      //   },
      // },
      // {
      //   key: "state",
      //   label: "State  *",
      //   span: "col-md-3",
      //   placeholder: "State",
      //   type: "select",
      //   options: stateList,
      //   showSearch: true,
      //   keyAccessor: (x) => x.name,
      //   valueAccessor: (x) => `${x.name}`,
      //   onChange: (x) => {
      //     props.form.setFieldsValue({
      //       state: x,
      //     });
      //   },
      // },
      
    ],
    billingAddress: [
      // {
      //   key: "billingCountry",
      //   label: "Country *",
      //   span: "col-md-3",
      //   placeholder: "Country",
      //   type: "select",
      //   options: Countries,
      //   showSearch: true,
      //   keyAccessor: (x) => x.name,
      //   valueAccessor: (x) => `${x.name}`,
      //   onChange: (x) => {
      //     props.form.setFieldsValue({
      //       billingCountry: x,
      //     });
      //     events.getState(x);
      //   },
      // },
      {
        key: "billingState",
        label: "State  *",
        span: "col-md-4",
        placeholder: "State",
        type: "select",
        options: stateList,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            billingState: x,
          });
        },
      },
      {
        key: "billingCity",
        label: "City *",
        span: "col-md-4",
        placeholder: "City",
      },
      {
        key: "billingPincode",
        label: "Pincode *",
        span: "col-md-4",
        placeholder: "Pincode",
      },
      {
        key: "billingAddress",
        label: "Address *",
      
        placeholder: "Address",
        type: "textarea",
        style:{height: "20px"}
        
      },
    ],
    shippingAddress: [
      // {
      //   key: "shippingCountry",
      //   label: "Shipping Country *",
      //   span: "col-md-3",
      //   placeholder: "Shipping Country",
      //   type: "select",
      //   options: Countries,
      //   showSearch: true,
      //   keyAccessor: (x) => x.name,
      //   valueAccessor: (x) => `${x.name}`,
      //   onChange: (x) => {
      //     props.form.setFieldsValue({
      //       shippingCountry: x,
      //     });
      //     events.getShippingState(x);
      //   },

      // },
      {
        key: "shippingState",
        label: "Shipping State *",
        span: "col-md-4",
        placeholder: "Shipping State",
        type: "select",
        options: shippingStateList,
        showSearch: true,
        keyAccessor: (x) => x.name,
        valueAccessor: (x) => `${x.name}`,
        onChange: (x) => {
          props.form.setFieldsValue({
            shippingState: x,
          });
        },

      },
      {
        key: "shippingCity",
        label: "Shipping City *",
        span: "col-md-4",
        placeholder: "Shipping City",

      },
      {
        key: "shippingPincode",
        label: "Shipping Pincode *",
        span: "col-md-4",
        placeholder: "Shipping Pincode",

      },
      {
        key: "shippingAddress",
        label: "Shipping Address *",
        span: "col-md-12",
        placeholder: "Shipping Address",
        type: "textarea",
    

      },
    ],
  
  };

  const handleCheckboxChange = (e) => {
    setCopyBillingAddress(e.target.checked); // Step 3: Handle checkbox state change
    if (e.target.checked) {
      // If the checkbox is checked, copy the billing address to the shipping address
      props.form.setFieldsValue({
        shippingCountry: getFieldValue("billingCountry"),
        shippingState: getFieldValue("billingState"),
        shippingCity: getFieldValue("billingCity"),
        shippingPincode: getFieldValue("billingPincode"),
        shippingAddress: getFieldValue("billingAddress"),
      });
    } else {
      props.form.setFieldsValue({
        shippingCountry: "",
        shippingState: "",
        shippingCity: "",
        shippingPincode: "",
        shippingAddress: "",
      });

    }
  };


  return (
  
    <Form onSubmit={handleSubmit}>
      <div className="form-elements-wrapper">
        <div className={"row"}>
          {inputTypes.fields.map((item, key) => {
            return !item.hidden ? (
              <div
                className={` ${item.span ? item.span : "col-md-6"}`}
                key={key}
              >
                <GetEachFormFields {...props.form} item={item} />
              </div>
            ) : null;
          })}
        </div>
        <div className={"custom_card"}>
          <h5 className={"custom_header"}>Billing Address</h5>
          <div className={"custom_body"}>
            <div className={"row"}>
              {inputTypes.billingAddress.map((item, key) => {
                return (
                  <div className={`pb-3 ${item.span}`} key={key}>
                    <GetEachFormFields {...props.form} item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={"custom_card"}>
          <h5 className={"custom_header"}>Shipping Address</h5>
          <div className={"custom_body"}>
            <div className={"row"}>
              <div className="col-md-12 mt-2 mb-3">
                <label>
                  <input
                    className="m-2"
                    type="checkbox"
                    checked={copyBillingAddress}
                    onChange={handleCheckboxChange}
                  />
                  Same as Billing Address
                </label>
              </div>

              {inputTypes.shippingAddress.map((item, key) => {
                return (
                  <div className={`pb-3 ${item.span}`} key={key}>
                    <GetEachFormFields {...props.form} item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      

      <button type="submit" className="btn btn-primary">
                    Submit
                </button>
      <HyperLink className=" ms-4 btn btn-danger" link={"/customers"}>
        Cancel
      </HyperLink>
    </Form>
 
  );
};

export default Form.create()(AddCustomerComponent);
