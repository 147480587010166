import {addCustomerUrl, addPosCustomerUrl, getPosCustomerUrl, getcustomerUrl, singleCustomerUrl, updateCustomerUrl} from "../api";
import {hideLoader, showLoader} from "../../../actions/loader";
import {notification} from "../../../components/Elements/appUtils";
import axios from "axios";
import { getToken } from "../../../request";


export const fetchCustomer = async () => {
    let config = {
        // params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getcustomerUrl(), config);
    return data;
};

export const fetchPosCustomer = async (valData) => {
    let config = {
        params: {...valData},
        ...await getToken()
    }
    const {data} = await axios.get(getPosCustomerUrl(), config);
    return data;
};


export const addCustomerFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addCustomerUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const addPosCustomerFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    const {data} = await axios.post(addPosCustomerUrl(), valData, getToken());
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({message: data.message || "Success"})
    } else {
        notification.error({message: data.message || "Error"})
    }
    return data;
};

export const singleCustomerFxn = (id) => async (dispatch) => {
    dispatch(showLoader());
    
    let { data } = await axios.get(singleCustomerUrl(id), getToken());
    console.log(data);
    dispatch(hideLoader());
    if (data.error) {
      notification.error({
        message: data.message || "Error",
      });
    }
  
    return data;
  };

  export const updateCustomerFxn = (valData) => async (dispatch) => {
    dispatch(showLoader());
    let {data} = await axios.post(updateCustomerUrl(), valData, getToken());
    dispatch(hideLoader());
    if (data.error) {
        notification.error({
            message: data.message || "Error",
        });
    } else {
        notification.success({
            message: data.message || "Success",
        });
    }
    return data;
  };