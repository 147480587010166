import React, {useRef} from "react";
import {Table} from "../../components/Elements/appUtils";

import PageHeader from "../../components/Elements/pageHeader";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import Image from "../../edit (1).png"
import { fetchSaleProduct } from "./action";

function SaleProductList() {
    let tableRef = useRef();
    const navigate = useNavigate()
    const apiRequest = (params) => {
        return new Promise(async (resolve) => {
            try {
                const data = await fetchSaleProduct({...params});
                resolve(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        });
    };

    const columns = [
        {
            title: "Sr. No",
            dataIndex: "serialNo",
            key: "serialNo",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Hsn Code",
            dataIndex: "hsnCode",
            key: "hsnCode",
        },
      
     
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: (item) => {
                return item ? <>Rs. {item}</> : ""
            }
        },
      
        // {
        //     title: "Apply-Discount/Coupon",
        //     dataIndex: "coupon",
        //     key: "coupon",
        // },

        // {
        //     title: "Gst",
        //     dataIndex: "gst",
        //     key: "gst",
        // },
       
    ];

    const getColumns = () => {
        let columnArr = [];
        _.each(columns, (item) => {
            if (!item.hide) {
                columnArr.push(item);
            }
        });
        return columnArr;
    };

    return (
        <PageHeader
            title={"Products"}
            extraLink={[
                {
                    name: "Add Product",
                    link: "/addproduct",
                },
            ]}
        >
            <div className="card mb-0">
                <div className="table-responsive">
                    <Table
                        apiRequest={apiRequest}
                        columns={getColumns()}
                        ref={tableRef}
                    />
                </div>
            </div>
        </PageHeader>
    );
}

export default SaleProductList;
