import React, { useEffect, useRef, useState } from "react";
import { Table } from "../../components/Elements/appUtils";
import PageHeader from "../../components/Elements/pageHeader";

import moment from "moment";
import { PrintSaleUrl } from "./api";
import { getToken } from "../../request";
import axios from "axios";
const PrintSale = (props) => {
  const [allDetail, setAllDetail] = useState([]);
  const [saleRecord, setSaleRecord] = useState([]);
  const [customerRecord, setCustomerRecord] = useState("");

    const apiRequest = () => {
      return new Promise(async (resolve) => {
        try {
          let searchParams = new URLSearchParams(window.location.search);
          let id = searchParams.get("_id");

          const response = await axios.get(PrintSaleUrl(id), getToken());
          console.log(response.data.sale, "_id");
          
            setSaleRecord(response.data.datas)
          setCustomerRecord(response.data.sale);

          console.log("Invoice Numbers:", response.data);

          resolve(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      });
    };

  //   const getStateNameFromCode = () => {
  //     const foundState = States.find((state) => state.stateCode == saleRecord.stateOfSupply);
  //     return foundState ? foundState.name : "State Not Found";
  //   };

    function convertNumberToWords(num) {
      const units = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const teens = [
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];

      if (num === 0) return "Zero";

      let words = "";

      if (num >= 1000 && num < 10000) {
        words += `${units[Math.floor(num / 1000)]} Thousand `;
        num %= 1000;
      }

      if (num >= 100 && num < 1000) {
        words += `${units[Math.floor(num / 100)]} Hundred `;
        num %= 100;
      }

      if (num >= 20 && num < 100) {
        words += `${tens[Math.floor(num / 10)]} `;
        num %= 10;
      }

      if (num > 10 && num < 20) {
        words += `${teens[(num % 10) - 1]} `;
        num = 0;
      }

      if (num > 0 && num < 10) {
        words += `${units[num]} `;
      }

      return words.trim();
    }
    useEffect(() => {
      apiRequest();
    }, []);

  return (
    <>
      <div>
        <div className="container">
          <div
            className="row"
            style={{ border: "1px solid black", padding: "8px" }}
          >
            <div className="col-sm-8">
              <h6 style={{ fontWeight: "bold" }}>RGS HOTELS AND BANQUETS</h6>
            </div>
            <div className="col-sm-4">
              <p style={{ textAlign: "right" }}>GST No: 06CMWPA8148P1Z6</p>
            </div>
          </div>
          <div
            className="row"
            style={{
              border: "1px solid black",
              borderTop: "none",
              padding: "10px",
            }}
          >
            <div className="col-sm-12">
              <p>
                Alpha Center, Sector 28 A, Alpha International City, Karnal,
                Haryana 132001
              </p>
              <p>Tel. (M) +91 72777 33333</p>
            </div>
          </div>

          <div className="PrintSaleUrl">
            <p
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "20px",
              }}
            >
              Tax Invoice
            </p>
            <div className="row mt-4">
              <div className="col-sm-6 mt-4">
                <p>Name:   :&nbsp;&nbsp; {customerRecord.customerId?.name}</p>
                <p>Address  :&nbsp;&nbsp;{customerRecord.customerId?.billingAddress}, {customerRecord.customerId?.billingCity}, {customerRecord.customerId?.billingState}
                , {customerRecord.customerId?.billingPincode}</p>
                <p>Phone No: :&nbsp;&nbsp; {customerRecord.customerId?.mobileNo}</p>
                {customerRecord.customerId?.gstIn && (
    <p>Customer GSTIN: :&nbsp;&nbsp; {customerRecord.customerId.gstIn}</p>
  )}
              </div>
              <div className="col-sm-6 mt-4">
                <p>Invoice Number:   :&nbsp;&nbsp;{customerRecord?.invoiceNumber} </p>
                <p>Invoice Date:   :&nbsp;&nbsp;{" "}
                    {moment(customerRecord.invoiceDate).format("DD/MM/YYYY")}</p>
              </div>
            </div>
            <p className="title mt-4" style={{ fontSize: "13px" }}></p>
            <table class="table table-bordered mt-4" style={{border: "1px solid black"}} >
            <thead style={{border: "1px solid black"}}>
              <tr  style={{border: "1px solid black"}}>
                <th>
                  <b>Sr</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Name</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>HSN</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>UOM</b>
                </th>
              
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Qty</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Dis</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Rate</b>
                </th>
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Total Amt</b>
                </th>
               
                <th className="table-heading" style={{ fontSize: "11px" }}>
                  <b>Tax Amt</b>
                </th>

                {customerRecord.cgstAmount !== 0 && customerRecord.sgstAmount !== 0 ? (
                  <>
                    <th className="table-heading" style={{ fontSize: "11px" }}><b>CGST</b></th>
                    <th className="table-heading" style={{ fontSize: "11px" }}><b>SGST</b></th>
                  </>
                ) : customerRecord.cgstAmount === 0 ||
                customerRecord.sgstAmount === 0 ? (
                  <th className="table-heading" style={{ fontSize: "11px" }}><b>IGST</b></th>
                ) : null}

                <th className="table-heading" style={{ fontSize: "11px" ,  width: "20px"}}>
                  <b>Net Amt</b>
                </th>
              </tr>
            </thead>

            <tbody>
              {saleRecord.map((i, index) => (
                <tr key={index} style={{border: "1px solid black"}}>
                  <td style={{ fontSize: "13px" }}>{index + 1}</td>

                  <td style={{ fontSize: "13px" }}>{i.productName}</td>
                  <td style={{ fontSize: "13px" }}>{i.hsn}</td>
                  <td style={{ fontSize: "13px" }}>{i.uom}</td>
                  <td style={{ fontSize: "13px" }}>{i.quantity}</td>
                         
                  <td style={{ fontSize: "13px" }}>{i.discount}</td>
                  <td style={{ fontSize: "13px" }}> ₹ {i.price}</td>
                  <td style={{ fontSize: "13px" }}> ₹ {i.price}</td>
     
                  <td style={{ fontSize: "13px" }}> ₹ {i.taxableAmount}</td>
                  {i.igstAmount !== 0 ? (
                    <>
                      {" "}
                      <td style={{ fontSize: "13px" }}> ₹ {i.igstAmount}</td>
                    </>
                  ) : i.sgstAmount !== 0 && i.cgstAmount !== 0 ? (
                    <>
                      <td style={{ fontSize: "13px" }}>₹ {i.cgstAmount}</td>
                      <td style={{ fontSize: "13px" }}>₹ {i.sgstAmount}</td>
                    </>
                  ) : (
                    ""
                  )}
                  <td style={{ fontSize: "13px" }}> ₹ {i.netAmount}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <div className="Total-section mt-4">
            <div className="row">
              <div className="col-sm-7 mt-1">
                {/* <h6 style={{ fontSize: "13px" }}>Payment Details:</h6>
                <div
                  style={{
                    borderBottom: "1px solid #000",
                    marginTop: "7px",
                    width: "300px",
                  }}
                ></div>

                <div
                  style={{
                    borderBottom: "1px solid #000",
                    marginTop: "10px",
                    width: "300px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: "13px" }}>PAYMODE</span>
                  <span style={{ fontSize: "13px" }}>AMOUNT</span>
                </div> */}
              </div>
              <div className="col-sm-5" style={{ border: "1px solid black" }}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-5">
                        <div style={{ textAlign: "right" }}>
                          <p style={{ fontSize: "13px" }}>
                            <b>Taxable Amt :</b>
                          </p>
                          {customerRecord.cgstAmount !== 0 &&
                        saleRecord.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              <b>CGST Total :</b>{" "}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              <b>SGST Total :</b>{" "}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            <b>IGST Total : </b>
                          </p>
                        )}
                          <p style={{ fontSize: "13px" }}>
                            <b>Total Amt :</b>
                          </p>
                          <div
                            style={{
                              borderBottom: "1px solid #000",
                              marginTop: "7px",
                              width: "260%",
                              marginLeft: "8px",
                              textAlign: "right",
                            }}
                          ></div>
                          <p style={{ fontSize: "13px" }}>
                            <b>Paid Amt :</b>
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <div style={{ textAlign: "right" }}>
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            ₹ {parseFloat(customerRecord.taxableAmount).toFixed(2)}
                          </p>
                          {customerRecord.cgstAmount !== 0 &&
                        customerRecord.sgstAmount !== 0 ? (
                          <>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹{parseFloat(customerRecord.cgstAmount).toFixed(2)}
                            </p>
                            <p style={{ fontSize: "13px" }}>
                              {" "}
                              ₹{parseFloat(customerRecord.sgstAmount).toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p style={{ fontSize: "13px" }}>
                            {" "}
                            ₹ {parseFloat(customerRecord.igstAmount).toFixed(2)}
                            
                          </p>
                        )}
                        <p style={{ fontSize: "13px" }}>
                          ₹ {parseFloat(customerRecord.gstAmount).toFixed(2)}
                        </p>
                        <p style={{ fontSize: "13px" , marginTop: "8px"}}>
                          ₹ {customerRecord.netAmount}
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Amount-section" style={{ fontSize: "13px" }}>
              <b>Amount In Words :</b> Rupees{" "}
              {convertNumberToWords(customerRecord.netAmount)} only
            </div>
            <div className="footer-section mt-4">
              <div className="row">
                <div className="col-sm-6">
                  <div
                    style={{
                      borderBottom: "1px solid #000",
                      marginTop: "15%",
                      width: "160px",
                    }}
                  ></div>
                  <p className="mt-3">Authorised Signature</p>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintSale;
